import { Divider, Grid, IconButton, Link, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { DialogContainer } from 'src/components/ui/dialogs/DialogContainer';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import { ProductItemPack } from 'src/api/marketx';

type ArrivalPackInfoDialogProps = {
  isDialogOpen: boolean;
  onClose: () => void;
  pack: ProductItemPack;
};

export const ArrivalPackInfoDialog: FC<ArrivalPackInfoDialogProps> = observer(({ isDialogOpen, onClose, pack }) => {
  return (
    <DialogContainer
      open={isDialogOpen}
      onClose={onClose}
      fullWidth
      canselBtnText="Закрыть"
      dialogContent={
        <Grid item>
          <Grid container alignItems="center" gap={1}>
            <IconButton disabled>
              <WidgetsOutlinedIcon />
            </IconButton>
            <Typography fontSize={'16px'} color="text.secondary">
              {pack.number}
            </Typography>
            <Link
              href={`/app/batches/${pack.batchNumber}`}
              sx={{ textTransform: 'uppercase', fontSize: '16px' }}
              underline="none"
              target="_blank"
            >
              {'К партии >>'}
            </Link>
          </Grid>
          <Divider sx={{ mb: 1 }} />

          <Grid container justifyContent={'space-between'}>
            <Grid item>
              <Typography variant={'body1'}>Параметр:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant={'body1'}>{pack?.parameter || '-'}</Typography>
            </Grid>
          </Grid>

          <Grid container justifyContent={'space-between'}>
            <Grid item>
              <Typography variant={'body1'}>Упаковка:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant={'body1'}>{pack?.boxingNumber || '-'}</Typography>
            </Grid>
          </Grid>
        </Grid>
      }
    />
  );
});
