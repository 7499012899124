import React, { FC, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { ContractListStore } from '../../../store/ContractListStore';
import { AppClientContract } from '../../../slices/AppDeal';
import { CustomSelectNew } from '../../../views/deals/components/CustomSelectNew';
import { ClientContract } from '../../../api/marketx';
import { BillItemStore } from 'src/store/Documents/BillItemStore';

interface ContractSelectProps {
  isViewOnly?: boolean;
  contracts: ContractListStore;
  current?: string;
  onChange: (contract: string | null) => void;
  // дополнительный элемент, который добавляется в непустой список
  prefixValue?: { text: string; value: string; customerCode: string };
  // дополнительный элемент, который вставляется в пустой список
  defaultValue?: { text: string; value: string; customerCode: string };
  // элемент, который должен быть обязательно в списке, если такой уже есть - ничего не добавляется, если нет - добавляется
  mustValue?: { text: string; value: string; customerCode: string };
  clientCode?: string;
  currentPayerCode?: string;
  billStore?: BillItemStore;
}

export function formatContractTitle(c: AppClientContract | ClientContract): string {
  if (!c?.number) {
    return c?.description;
  }
  return c?.number + (c?.description ? ' - ' + c?.description : '');
}

export const ContractSelect: FC<ContractSelectProps> = observer(
  ({
    isViewOnly,
    current,
    contracts,
    onChange,
    prefixValue,
    defaultValue,
    mustValue,
    clientCode,
    currentPayerCode,
    billStore,
  }): JSX.Element => {
    const items = currentPayerCode
      ? contracts.items.filter(contract => contract?.customer?.code === currentPayerCode || contract?.customer?.code === clientCode)
      : contracts.items;
    const values = useMemo(() => {
      if (!contracts.items.length) {
        return [];
      }
      const values = items.map((c: AppClientContract) => {
        return { value: c.code, text: formatContractTitle(c), customerCode: c?.customer?.code };
      });
      if (values.length > 1) {
        if (prefixValue) {
          values.unshift(prefixValue); // { text: '\u00A0', value: '' });
        }
      } else if (defaultValue) {
        values.unshift(defaultValue);
      }
      if (mustValue && !values.some(item => item.value === mustValue.value)) {
        values.unshift(mustValue);
      }
      if (!values.length) {
        values.push({ text: '\u00A0', value: '', customerCode: '' });
      }
      return values;
    }, [contracts.isLoaded, contracts.items, contracts.items?.length, currentPayerCode]);

    const handleChange = useCallback(
      (value: string | null) => {
        if (onChange) {
          onChange(value || null);
        }
      },
      [onChange, contracts]
    );

    const handleChangePowerOfAttorney = (customerCode?: string): void => {
      billStore && customerCode && billStore.loadDocFlowPowerOfAttorneyByPayerCode(customerCode);
    };

    const isViewOnlyList = useMemo(() => {
      return isViewOnly || !contracts.isLoaded;
    }, [isViewOnly, contracts, contracts.isLoaded]);

    return (
      <CustomSelectNew
        label={'Договор'}
        options={values}
        isViewOnly={isViewOnlyList}
        defaultValue={current}
        onChange={handleChange}
        clientCode={clientCode}
        handleChangePowerOfAttorney={handleChangePowerOfAttorney}
      />
    );
  }
);
