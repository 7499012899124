import { observer } from 'mobx-react-lite';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ProductItemPack } from 'src/api/marketx';
import { ProductsNormalType } from 'src/slices/AppCatalog';
import ProductIndicators, { BatchT } from '../ProductIndicators';
import { Fade, Grid, IconButton, TableCell, TableRow, useTheme } from '@mui/material';
import LightTooltip from 'src/components/ToolTip/LightTooltip';
import { formatNumber02, formatNumber3, formatNumberByUnit, formatPriceCur } from '@mx-ui/helpers';
import { Box } from '@mui/system';
import { calculateBatchIndicators } from 'src/store/BatchesListStore';
import { red } from '@mui/material/colors';
import DownloadIcon from '@mui/icons-material/Download';
import ChromeReaderModeRoundedIcon from '@mui/icons-material/ChromeReaderModeRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ArrivalPackTableBodyDetails } from './ArrivalPackTableBodyDetails';
import { ArrivalPartyInfoDialog } from './ArrivalPartyInfoDialog';
import { Link as MxLink } from '@mx-ui/ui';
import { AppDealPosition } from 'src/slices/AppDeal';
import { ProductItemStore } from 'src/store/ProductItemStore';
import { DealItemStore, UpdateQueueItem } from 'src/store/DealItemStore';
import { AgreementItemStore } from 'src/store/Clients/AgreementItemStore';
import { NewCatalogStore } from 'src/store/Catalog/NewCatalogStore';

export const valueTODHByDate = (days: number): { text: string; color: string } => {
  switch (true) {
    case days >= 30 && days < 60:
      return {
        text: 'Т30',
        color: red[100],
      };
    case days >= 60 && days < 90:
      return {
        text: 'Т60',
        color: red[200],
      };
    case days >= 90 && days < 180:
      return {
        text: 'Т90',
        color: red[300],
      };
    case days >= 180 && days < 240:
      return {
        text: 'Т180',
        color: red[400],
      };
    case days >= 240 && days < 360:
      return {
        text: 'Т240',
        color: red[500],
      };
    case days >= 360 && days < 600:
      return {
        text: 'Т360',
        color: red[600],
      };
    case days >= 600 && days < 1000:
      return {
        text: 'Т600',
        color: red[700],
      };
    case days >= 1000:
      return {
        text: 'Т1000',
        color: red[800],
      };

    default:
      return {
        text: undefined,
        color: undefined,
      };
  }
};

type ItemPack = ProductItemPack & {
  inStockTne: number;
  inStockAltQuantity?: number;
  inStockAltUnitShort?: string;
  items: ProductItemPack[];
};

type ArrivalPartyTableBodyDetailsProps = {
  store?: ProductItemStore | DealItemStore | AgreementItemStore | NewCatalogStore;
  product: ProductsNormalType;
  batch: ItemPack;
  dealPosition?: AppDealPosition;
  infiniteScrollId?: string;
  handleModalOpen: (code: string, shipmentCode: string, number: string) => void;
  isAllArrivalPackOpen: boolean;
};

export const ArrivalPartyTableBodyDetails: FC<ArrivalPartyTableBodyDetailsProps> = observer(
  ({ batch, product, handleModalOpen, isAllArrivalPackOpen, dealPosition, store }) => {
    const [isArrivalPartyOpen, setIsArrivalPartyOpen] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const handleOpenDialog = (): void => {
      setIsDialogOpen(true);
    };

    const handleCloseDialog = (): void => {
      setIsDialogOpen(false);
    };

    const tableCellRef = useRef(null);

    const hasAltUnits = product?.multiplicityUnitCode !== 'TNE' && product?.multiplicity > 0;
    const altFactor = hasAltUnits ? 1 / product?.multiplicity : 1;
    const { color: todhColor } = valueTODHByDate(batch?.todhDays);

    const handleAddToCommentClick = useCallback(
      (pack: ProductItemPack): Promise<UpdateQueueItem> => {
        if (store instanceof DealItemStore) {
          return store.addPackToPositionComment(dealPosition, pack);
        }
      },
      [store, dealPosition]
    );

    let indicatorsCosts = calculateBatchIndicators(null, batch);

    if (dealPosition) {
      indicatorsCosts = calculateBatchIndicators(dealPosition, batch);
    } else if (product) {
      indicatorsCosts = calculateBatchIndicators(product, batch);
    }

    const isShowButton = store instanceof DealItemStore && store && !store.isViewOnly() && !batch.batchDoNotSell;

    const theme = useTheme();

    const indicatorsBatch = useMemo<BatchT>(() => {
      return {
        todhDiscountPct: batch?.todhDiscountPct,
        todhDiscountIlliquidBlocked: batch?.todhDiscountIlliquidBlocked,
        illiquidDefectCode: batch?.illiquidDefectCode,
        illiquidDefectTitle: batch?.illiquidDefectTitle,
        illiquidRustCode: batch?.illiquidRustCode,
        illiquidRustTitle: batch?.illiquidRustTitle,
        illiquidDiscountPct: batch?.illiquidDiscountPct,
        isSafeCustody: batch?.isSafeCustody,
        selfPurchase: batch?.batchSelfPurchase,
      };
    }, [batch]);

    const handleCollapseOpen = (): void => {
      if (isArrivalPartyOpen) {
        setIsArrivalPartyOpen(false);
      } else {
        setIsArrivalPartyOpen(true);
      }
    };

    useEffect(() => {
      setIsArrivalPartyOpen(isAllArrivalPackOpen);
    }, [isAllArrivalPackOpen]);

    return (
      <>
        <TableRow
          key={batch.batchCode}
          sx={{
            cursor: 'pointer',
            mb: 1,
          }}
          onClick={handleCollapseOpen}
        >
          <TableCell>
            <Grid container flexDirection="row" alignItems="center" justifyContent="space-between">
              {batch.batchNumber ? (
                <MxLink href={`/app/batches/${batch.batchNumber}`} color="inherit" fontSize="14px" title="Перейти к партии" target="_blank">
                  {batch.batchNumber}
                </MxLink>
              ) : (
                '-'
              )}
              <Grid>
                <IconButton
                  onClick={e => {
                    e.stopPropagation();
                    handleOpenDialog();
                  }}
                  size="small"
                >
                  <ChromeReaderModeRoundedIcon color="primary" />
                </IconButton>
                {batch.items?.length && (
                  <IconButton size={'small'} onClick={handleCollapseOpen} title={'Раскрыть'}>
                    <KeyboardArrowDownIcon
                      sx={{
                        transform: `rotate(${isArrivalPartyOpen ? 180 : 0}deg)`,
                        transition: 'all .3s ease',
                      }}
                    />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </TableCell>
          <TableCell>{batch?.warehouseName || '–'}</TableCell>
          <TableCell>{batch?.wagonNumber || '-'}</TableCell>
          <TableCell>{'-'}</TableCell>
          <TableCell>
            {batch?.weight > 0 && hasAltUnits ? (
              <LightTooltip
                arrow
                placement="bottom"
                TransitionComponent={Fade}
                title={<Box p={2}>{formatNumberByUnit(batch?.weight * altFactor, 'TNE', 'т')}</Box>}
              >
                <span>{`${formatNumber3(batch?.weight)} т`}</span>
              </LightTooltip>
            ) : (
              `${formatNumber3(batch?.weight)} т`
            )}
          </TableCell>
          <TableCell>
            {batch?.inStockTne ? (
              hasAltUnits ? (
                <LightTooltip
                  arrow
                  placement="bottom"
                  TransitionComponent={Fade}
                  title={<Box p={2}>{formatNumberByUnit(batch?.inStockTne * altFactor, batch?.unitCode, batch?.unitShortName)}</Box>}
                >
                  <span>{formatNumberByUnit(batch?.inStockTne, batch?.unitCode, batch?.unitShortName)}</span>
                </LightTooltip>
              ) : (
                formatNumberByUnit(batch?.inStockTne, batch?.unitCode, batch?.unitShortName)
              )
            ) : (
              '-'
            )}
          </TableCell>
          <TableCell>{batch?.receiptDate}</TableCell>
          <TableCell align="right">
            {batch?.todhDiscountPct > 0 ? (
              <Box
                display={'inline'}
                px={0.5}
                py={0.2}
                bgcolor={todhColor}
                color={todhColor ? theme.palette.getContrastText(todhColor) : undefined}
                title={[
                  'Скидка ' + formatNumber02(batch?.todhDiscountPct) + '%',
                  'МРЦ-' + formatNumber02(batch?.todhDiscountPct) + '% = ' + formatPriceCur(indicatorsCosts?.todhMrcUnitCost),
                ].join('\n')}
              >
                {batch?.todhText || '–'}
              </Box>
            ) : (
              <Box
                display={'inline'}
                px={0.5}
                py={0.2}
                bgcolor={todhColor}
                color={todhColor ? theme.palette.getContrastText(todhColor) : undefined}
              >
                {batch?.todhText || '–'}
              </Box>
            )}
          </TableCell>
          <TableCell ref={tableCellRef} align="left">
            <ProductIndicators batch={indicatorsBatch} tableCellRef={tableCellRef} costs={indicatorsCosts} />
          </TableCell>
          <TableCell align="right">
            {!!batch.certificateName ? (
              <Grid item ml={0.5} title={'Скачать сертификат'}>
                <IconButton
                  size="small"
                  sx={{ p: '4px' }}
                  onClick={e => {
                    e.stopPropagation();
                    handleModalOpen(batch?.batchCode, '', batch?.batchCode);
                  }}
                >
                  <DownloadIcon fontSize="small" />
                </IconButton>
              </Grid>
            ) : (
              '-'
            )}
          </TableCell>
        </TableRow>

        {isArrivalPartyOpen &&
          batch.items?.map(pack => (
            <ArrivalPackTableBodyDetails
              pack={pack}
              product={product}
              key={pack.number}
              onClick={handleAddToCommentClick}
              isShowButton={isShowButton}
            />
          ))}

        <ArrivalPartyInfoDialog isDialogOpen={isDialogOpen} onClose={handleCloseDialog} batch={batch} />
      </>
    );
  }
);
