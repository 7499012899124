import { Box, Button, Fade, Grid, IconButton, TableCell, TableRow, Typography, useTheme } from '@mui/material';
import { formatNumber02, formatNumber3, formatNumberByUnit, formatPriceCur } from '@mx-ui/helpers';
import { observer } from 'mobx-react-lite';
import { FC, useMemo, useRef, useState } from 'react';
import { Product, ProductItemPack } from 'src/api/marketx';
import LightTooltip from 'src/components/ToolTip/LightTooltip';
import { calculateBatchIndicators } from 'src/store/BatchesListStore';
import { valueTODHByDate } from './ArrivalPartyTableBodyDetails';
import ProductIndicators, { BatchT } from '../ProductIndicators';
import ChromeReaderModeRoundedIcon from '@mui/icons-material/ChromeReaderModeRounded';
import { ArrivalPackInfoDialog } from './ArrivalPackInfoDialog';
import { Link as MxLink } from '@mx-ui/ui';
import { UpdateQueueItem } from 'src/store/DealItemStore';

type ArrivalPackTableBodyDetailsProps = {
  pack: ProductItemPack;
  product: Product;
  onClick?: (pack: ProductItemPack) => Promise<UpdateQueueItem>;
  isShowButton?: boolean;
};

export const ArrivalPackTableBodyDetails: FC<ArrivalPackTableBodyDetailsProps> = observer(({ pack, product, onClick, isShowButton }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [added, setAdded] = useState<boolean>(false);

  const handleDialogOpen = (): void => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = (): void => {
    setIsDialogOpen(false);
  };

  const handleClick = (pack: ProductItemPack): void => {
    setAdded(true);

    onClick(pack).catch(() => {
      setAdded(false);
    });
  };

  const hasQuantityUnit = pack?.quantity > 0 && pack?.quantity !== pack?.weight;
  const inStockToTneRequired =
    pack?.inStockUnitCode && pack?.inStockUnitCode == product?.multiplicityUnitCode && product?.multiplicity && product?.multiplicity !== 1;
  const inStockToTneFactor = inStockToTneRequired ? product?.multiplicity : 1;

  const tableCellRef = useRef(null);
  const indicatorsCosts = calculateBatchIndicators(null, pack);

  const theme = useTheme();

  const { color: todhColor } = valueTODHByDate(pack.todhDays);

  const indicatorsPack = useMemo<BatchT>(() => {
    return {
      todhDiscountPct: pack?.todhDiscountPct,
      todhDiscountIlliquidBlocked: pack?.todhDiscountIlliquidBlocked,
      illiquidDefectCode: pack?.illiquidDefectCode,
      illiquidDefectTitle: pack?.illiquidDefectTitle,
      illiquidRustCode: pack?.illiquidRustCode,
      illiquidRustTitle: pack?.illiquidRustTitle,
      illiquidDiscountPct: pack?.illiquidDiscountPct,
      isSafeCustody: pack?.isSafeCustody,
      selfPurchase: pack?.batchSelfPurchase,
    };
  }, [pack]);

  return (
    <TableRow>
      <TableCell sx={{ pl: 4 }}>
        <Grid container alignItems="center" justifyContent="space-between">
          {pack.batchNumber ? (
            <MxLink href={`/app/batches/${pack.batchNumber}`} color="inherit" fontSize="14px" title="Перейти к партии" target="_blank">
              {pack.number}
            </MxLink>
          ) : (
            '-'
          )}
          <IconButton sx={{ mr: '34px' }} onClick={handleDialogOpen} size="small">
            <ChromeReaderModeRoundedIcon color="primary" />
          </IconButton>
        </Grid>
      </TableCell>
      <TableCell>{pack?.warehouseName || '–'}</TableCell>
      <TableCell>{pack?.wagonNumber || '-'}</TableCell>
      <TableCell>{pack?.cassette || '-'}</TableCell>
      <TableCell>
        {hasQuantityUnit ? (
          <LightTooltip
            arrow
            placement="bottom"
            TransitionComponent={Fade}
            title={<Box p={2}>{formatNumberByUnit(pack?.quantity, pack?.quantityUnitCode, pack?.quantityUnitShort)}</Box>}
          >
            <span>{`${formatNumber3(pack?.weight)} т`}</span>
          </LightTooltip>
        ) : (
          `${formatNumber3(pack?.weight)} т`
        )}
      </TableCell>
      <TableCell>{formatNumberByUnit(pack?.inStock * inStockToTneFactor, pack?.unitCode, pack?.unitShortName)}</TableCell>
      <TableCell>{pack?.receiptDate}</TableCell>

      <TableCell align="right">
        {pack?.todhDiscountPct > 0 ? (
          <Box
            display={'inline'}
            px={0.5}
            py={0.2}
            bgcolor={todhColor}
            color={todhColor ? theme.palette.getContrastText(todhColor) : undefined}
            title={[
              'Скидка ' + formatNumber02(pack?.todhDiscountPct) + '%',
              'МРЦ-' + formatNumber02(pack?.todhDiscountPct) + '% = ' + formatPriceCur(indicatorsCosts.todhMrcUnitCost),
            ].join('\n')}
          >
            {pack?.todhText || '–'}
          </Box>
        ) : (
          <Box
            display={'inline'}
            px={0.5}
            py={0.2}
            bgcolor={todhColor}
            color={todhColor ? theme.palette.getContrastText(todhColor) : undefined}
          >
            {pack?.todhText || '–'}
          </Box>
        )}
      </TableCell>

      <ArrivalPackInfoDialog isDialogOpen={isDialogOpen} onClose={handleDialogClose} pack={pack} />

      <TableCell align="right" ref={tableCellRef}>
        <ProductIndicators batch={indicatorsPack} tableCellRef={tableCellRef} costs={indicatorsCosts} />
      </TableCell>
      <TableCell>
        <Grid item container alignItems={'flex-end'}>
          {isShowButton && (
            <Box ml={'auto'} pt={1}>
              {!added ? (
                <Button
                  variant="outlined"
                  size="small"
                  title="Добавить запись с номером пачки в комментарий позиции"
                  sx={{ borderColor: '#bdbdbd' }}
                  onClick={() => handleClick(pack)}
                >
                  В&nbsp;комментарий
                </Button>
              ) : (
                <Typography style={{ fontSize: '12px', textAlign: 'center' }}>Добавлено</Typography>
              )}
            </Box>
          )}
        </Grid>
      </TableCell>
    </TableRow>
  );
});
