import CreditScoreOutlinedIcon from '@mui/icons-material/CreditScoreOutlined';
import DynamicFeedOutlinedIcon from '@mui/icons-material/DynamicFeedOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import Rotate90DegreesCcwIcon from '@mui/icons-material/Rotate90DegreesCcw';
import WarehouseOutlinedIcon from '@mui/icons-material/WarehouseOutlined';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import TocRoundedIcon from '@mui/icons-material/TocRounded';
import SearchIcon from '@mui/icons-material/Search';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import PeopleAltIcon from '@mui/icons-material/PeopleAltOutlined';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import ListIcon from '@mui/icons-material/FormatListBulletedOutlined';
import CleaningServicesOutlinedIcon from '@mui/icons-material/CleaningServicesOutlined';
import CompareArrowsOutlinedIcon from '@mui/icons-material/CompareArrowsOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined';
import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined';
import CardTravelOutlinedIcon from '@mui/icons-material/CardTravelOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import WorkIcon from '@mui/icons-material/WorkOutline';
import { HandShakeIcon } from 'src/components/ui/icons/HandShakeIcon';
import { setParent, Section as SectionType } from '@mx-ui/navbar';
import { AppUserGroupCodes } from 'src/types/AppUserGroup';
import { AuthStore } from 'src/store/AuthStore';
import AcUnitRoundedIcon from '@mui/icons-material/AcUnitRounded';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import LockPersonRoundedIcon from '@mui/icons-material/LockPersonRounded';
import OutletIcon from '@mui/icons-material/Outlet';

const edoAccessUserGroupCodes: string[] = [
  AppUserGroupCodes.ADMIN,
  AppUserGroupCodes.OFFICE_CHIEF,
  AppUserGroupCodes.COMPANY_CHIEF,
  AppUserGroupCodes.DOC_FLOW_ADMIN,
];

const accessUserGroupCodes: string[] = [
  AppUserGroupCodes.SELLER,
  AppUserGroupCodes.SELLER_CHIEF,
  AppUserGroupCodes.OFFICE_CHIEF,
  AppUserGroupCodes.OFFICE_MARKETER,
  AppUserGroupCodes.DIVISION_CHIEF,
  AppUserGroupCodes.COMPANY_CHIEF,
  AppUserGroupCodes.COMPANY_MARKETER,
  AppUserGroupCodes.ADMIN,
  AppUserGroupCodes.MOTIVATION_RESPONSIBLE,
  AppUserGroupCodes.CUSTOMER_EDITOR,
  AppUserGroupCodes.DIVISION_MARKETER,
  AppUserGroupCodes.CALL_CENTER_OPERATOR,
  AppUserGroupCodes.SUPPORT,
];

const accessProductsReport: string[] = [
  AppUserGroupCodes.OFFICE_CHIEF,
  AppUserGroupCodes.DIVISION_CHIEF,
  AppUserGroupCodes.COMPANY_CHIEF,
  AppUserGroupCodes.MRC_CONTROL,
  AppUserGroupCodes.SELLER_CHIEF,
  AppUserGroupCodes.OFFICE_MARKETER,
  AppUserGroupCodes.DIVISION_MARKETER,
  AppUserGroupCodes.COMPANY_MARKETER,
  AppUserGroupCodes.SELLER,
  AppUserGroupCodes.ADMIN,
];

const accessUserGroupCodesReports: string[] = [AppUserGroupCodes.MANAGEMENT_REPORTING];
export const motivationAccessFunc = (authStore: AuthStore): boolean => {
  return authStore.inAnyGroup([
    AppUserGroupCodes.ADMIN,
    AppUserGroupCodes.MOTIVATION_RESPONSIBLE,
    AppUserGroupCodes.OFFICE_CHIEF,
    AppUserGroupCodes.DIVISION_CHIEF,
    AppUserGroupCodes.COMPANY_CHIEF,
    AppUserGroupCodes.SELLER,
    AppUserGroupCodes.SELLER_CHIEF,
  ]);
};

export const freezeAccessFunc = (authStore: AuthStore): boolean => {
  return authStore.profile?.resources?.some(i => i.uri === 'shop://entity/freeze/list');
};

export const mainSections: SectionType[] = setParent([
  {
    title: 'Главная',
    name: 'home',
    icon: <HomeIcon />,
    href: '/app/home',
  },
  {
    title: 'Каталог',
    icon: <ListIcon />,
    name: 'catalog',
    href: '/app/catalog',
    access: [...accessUserGroupCodes, ...accessUserGroupCodesReports],
  },
  // {
  //   title: 'Каталог (Old)',
  //   icon: <ListIcon />,
  //   name: 'catalog2',
  //   href: '/app/catalog-old/goods',
  //   access: [AppUserGroupCodes.ADMIN],
  // },
  {
    title: 'Клиенты',
    icon: <PeopleAltIcon />,
    href: '/app/clients',
    name: 'clients',
  },
  {
    title: 'Продажи',
    name: 'sale',
    icon: <DynamicFeedOutlinedIcon />,
    // access: [AppUserGroupCodes.AUTHORIZED],
    items: [
      // {
      //   title: 'Прайс-лист',
      //   icon: <LocalOfferIcon />,
      //   name: 'price',
      //   href: '/app/price-list',
      //   access: accessUserGroupCodes,
      // },
      {
        title: 'Поиск',
        icon: <SearchIcon />,
        name: 'searchBySale',
      },
      {
        title: 'Договоры',
        icon: <AssignmentTurnedInOutlinedIcon />,
        href: '/app/contracts',
        name: 'contracts',
      },
      {
        title: 'Соглашения',
        icon: <HandShakeIcon />,
        href: '/app/agreements',
        name: 'agreements',
        accessFunc: (auth: AuthStore): boolean => {
          return Boolean(auth.profile?.features?.hasAgreementsAccess);
        },
      },

      {
        title: 'Заявки',
        icon: <AddCardOutlinedIcon />,
        href: '/app/deals',
        name: 'deals',
        access: accessUserGroupCodes,
      },
      {
        title: 'Счета',
        icon: <CreditCardOutlinedIcon />,
        href: '/app/bills',
        name: 'bills',
      },
      {
        title: 'Отгрузки',
        icon: <CardTravelOutlinedIcon />,
        href: '/app/shipments',
        name: 'shipments',
      },
      {
        title: 'Возвраты',
        icon: <Rotate90DegreesCcwIcon />,
        href: '/app/returns',
        name: 'returns',
      },
      {
        title: 'Оплаты',
        icon: <PaymentsOutlinedIcon />,
        href: '/app/payments',
        name: 'payments',
      },
      {
        title: 'Списание долга',
        icon: <CleaningServicesOutlinedIcon />,
        href: '/app/debts',
        name: 'debt_relief',
      },
      {
        title: 'Согласование',
        icon: <CreditScoreOutlinedIcon />,
        href: '/app/approvals',
        name: 'approvals',
        access: accessUserGroupCodes,
      },
      {
        title: 'ЭДО',
        icon: <CompareArrowsOutlinedIcon />,
        href: '/app/edo',
        name: 'edo',
        access: edoAccessUserGroupCodes,
        items: [
          {
            title: 'Сообщения ЭДО',
            icon: <ForwardToInboxOutlinedIcon />,
            href: '/app/edo/contracts',
            name: 'edoContracts',
            access: edoAccessUserGroupCodes,
          },
        ],
      },
    ],
  },
  {
    title: 'Склады',
    icon: <WarehouseOutlinedIcon />,
    name: 'warehouses',
    items: [
      {
        title: 'Партии',
        icon: <WidgetsOutlinedIcon />,
        name: 'batches',
        href: '/app/batches',
      },
      {
        title: 'Товары',
        icon: <TocRoundedIcon />,
        name: 'product-report',
        href: '/app/product-report',
        access: [...accessProductsReport],
      },
      {
        title: 'Резервы',
        icon: <LockPersonRoundedIcon />,
        name: 'reserves',
        href: '/app/reserves',
        accessFunc: (authStore: AuthStore): boolean => authStore.canViewReserveByBranch,
      },
      {
        title: 'Очередь резервов',
        icon: <DynamicFeedOutlinedIcon />,
        name: 'reserves-awaiting',
        href: '/app/reserves-awaiting',
        accessFunc: (authStore: AuthStore): boolean => authStore.canViewReserveByBranch,
      },
      {
        title: 'Фризы',
        icon: <AcUnitRoundedIcon />,
        name: 'freeze',
        href: '/app/freeze',
        accessFunc: freezeAccessFunc,
      },
    ],
  },
  {
    title: 'Аналитика и отчеты',
    icon: <QueryStatsIcon />,
    name: 'targets',
    href: '/app/report',
    access: [...accessUserGroupCodes, ...accessUserGroupCodesReports],
  },
  {
    title: 'Задачи',
    icon: <TaskOutlinedIcon />,
    name: 'tasks',
    href: '/app/tasks',
    items: [
      {
        title: 'Список',
        icon: <ListOutlinedIcon />,
        name: 'tasksList',
        href: '/app/tasks',
      },
      {
        title: 'Доска',
        icon: <DashboardOutlinedIcon />,
        name: 'tasksDesk',
        href: '/app/tasks/desk',
      },
      {
        title: 'Страница ошибки',
        icon: <OutletIcon />,
        name: 'errorPage',
        href: '/app/error',
        access: [AppUserGroupCodes.ADMIN],
      },
    ],
  },
]);
export const backOfficeSections: SectionType[] = setParent([
  {
    title: 'BackOffice',
    name: 'adm',
    icon: <WorkIcon />,
    href: '/adm',
    accessFunc: (auth: AuthStore): boolean => {
      return Boolean(auth.profile?.features?.hasBackofficeAccess);
    },
  },
]);
