import { Skeleton, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useMemo, useState } from 'react';
import { Product, ProductItemPack } from 'src/api/marketx';
import { ProductItemStore } from 'src/store/ProductItemStore';
import { ArrivalPartyTableBodyDetails } from './ArrivalPartyTableBodyDetails';
import { CertificateBatchModal } from 'src/components/Shipments/CertificateBatchModal';
import { ProductPacksStore } from 'src/store/ProductPacksStore';
import { DealItemStore } from 'src/store/DealItemStore';
import { AppDealPosition } from 'src/slices/AppDeal';
import { AgreementItemStore } from 'src/store/Clients/AgreementItemStore';
import { NewCatalogStore } from 'src/store/Catalog/NewCatalogStore';

type ArrivalPartyTableBodyProps = {
  store?: ProductItemStore | DealItemStore | AgreementItemStore | NewCatalogStore;
  product: Product;
  packsStore: ProductPacksStore;
  dealPosition?: AppDealPosition;
};

type ItemPack = ProductItemPack & {
  inStockTne: number;
  inStockAltQuantity?: number;
  inStockAltUnitShort?: string;
  items: ProductItemPack[];
};

type ItemsPack = Record<string, ItemPack>;

export const ArrivalPartyTableBody: FC<ArrivalPartyTableBodyProps> = observer(({ store, packsStore, product, dealPosition }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [certificateParams, setCertificateParams] = useState({ code: '', shipmentCode: '', number: '' });

  const items: ItemsPack = useMemo(
    () =>
      toJS(packsStore.packs).reduce<ItemsPack>((acc, pack) => {
        if (!acc[pack?.batchNumber]) {
          acc[pack?.batchNumber] = { ...pack, inStock: 0, inStockTne: 0, weight: pack?.batchWeight, items: [] };
        }

        const inStockToTneRequired =
          pack?.inStockUnitCode &&
          pack?.inStockUnitCode == product?.multiplicityUnitCode &&
          product?.multiplicity &&
          product?.multiplicity !== 1;

        const inStockToTneFactor = inStockToTneRequired ? product?.multiplicity : 1;
        acc[pack?.batchNumber].inStock += pack?.inStock;
        acc[pack?.batchNumber].inStockTne += pack?.inStock * inStockToTneFactor;
        acc[pack?.batchNumber].items.push(pack);

        return acc;
      }, {}),
    [packsStore?.packs, packsStore.packs?.length]
  );

  const handleModalOpen = (code: string, shipmentCode: string, number: string): void => {
    setCertificateParams({ code, shipmentCode, number });
    setIsModalOpen(true);
  };

  const handleModalClose = (): void => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (Object.keys(items)?.length < packsStore.packsCount && packsStore.hasMore) {
      packsStore.loadMore();
    }
  }, [packsStore, Object.keys(items)?.length, packsStore.hasMore]);

  if (!Object.keys(items)?.length) {
    return (
      <TableCell colSpan={10}>
        <Typography textAlign={'center'} sx={{ p: 2 }}>
          Партий не найдено
        </Typography>
      </TableCell>
    );
  }

  return (
    <TableBody>
      {(!packsStore.isLoaded && (
        <TableRow>
          {Array.from(new Array(8)).map((value, index) => (
            <TableCell key={index}>
              <Skeleton />
            </TableCell>
          ))}
        </TableRow>
      )) ||
        Object.keys(items)?.map(batchNumber => {
          return (
            <ArrivalPartyTableBodyDetails
              store={store}
              key={batchNumber}
              batch={items[batchNumber]}
              product={product}
              handleModalOpen={handleModalOpen}
              isAllArrivalPackOpen={store.isAllArrivalPackOpen}
              dealPosition={dealPosition}
            />
          );
        })}
      <CertificateBatchModal
        batchCode={certificateParams.code}
        batchNumber={certificateParams.number}
        shipmentCode={certificateParams.shipmentCode}
        isModalOpen={isModalOpen}
        closeModal={handleModalClose}
      />
    </TableBody>
  );
});
