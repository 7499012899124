import React, { FC, JSX, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { BillPosition, BillsItemEditCommentsTypeCodeEnum } from '../../../api/marketx';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { BillItemStore } from '../../../store/Documents/BillItemStore';
import TextField from '@mui/material/TextField';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import { BillSummaryTabHeader } from '../BillSummaryTabHeader';
import { BillPositionAdditionalTabHeader } from './BillPositionAdditionalTabHeader';
import { useDebounce } from 'src/utils/hooks/useDebounce';
import { Badge, Fade, Paper, Popper } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

interface BillAdditionalTabCommentProps {
  billStore: BillItemStore;
  position?: BillPosition;
  isDialogOpen?: boolean;
}

export const BillAdditionalTabComment: FC<BillAdditionalTabCommentProps> = observer(
  ({ billStore, position, isDialogOpen }): JSX.Element => {
    const bill = billStore.item;
    const currentCommentCode = position?.code || 'billSummary';

    const [anchorEl, setAnchorEl] = useState(null);
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    const handleTooltipOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
      event.stopPropagation();
      setAnchorEl(event.target);
      setIsTooltipOpen(true);
    };

    const handleTooltipClose = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
      event.stopPropagation();
      setAnchorEl(null);
      setIsTooltipOpen(false);
    };

    const isEditing = bill?.editingPermissions?.canUpdateStoreComments && billStore.isEditing && !billStore.isSaving;

    const handleUpdateComments = useDebounce(() => {
      const comments = billStore.getComments();
      billStore.updateBill({ comments: comments });
    }, 1200);

    const handleEditValue = (value: string): void => {
      billStore.addNewComment(value, currentCommentCode, BillsItemEditCommentsTypeCodeEnum.Store);
      handleUpdateComments();
    };

    const handleUpdateCommentsForShipment = useDebounce(() => {
      const comments = billStore.getComments();
      billStore.updateBill({ comments: comments });
    }, 1200);

    const handleEditValueCommentsForShipment = (value: string): void => {
      billStore.addNewComment(value, 'billSummaryForShipment', BillsItemEditCommentsTypeCodeEnum.OtherSaleShipmentInfo);
      handleUpdateCommentsForShipment();
    };

    return (
      <Grid item xs={12}>
        <Grid container alignItems={'center'}>
          {position ? (
            <BillPositionAdditionalTabHeader Icon={ChatOutlinedIcon} position={position} isDialogOpen={isDialogOpen} />
          ) : (
            <BillSummaryTabHeader Icon={ChatOutlinedIcon} billItemStore={billStore} isDialogOpen={isDialogOpen} />
          )}
          <Grid item xs={12}>
            <Typography variant="body2" color="text.secondary" sx={{ paddingTop: 1, paddingLeft: 0 }}>
              Комментарий для склада
            </Typography>
          </Grid>
          <Grid item xs={12} container alignItems={'center'} wrap={'nowrap'}>
            <Grid item flex={'1 1 auto'}>
              <TextField
                sx={{ '& .MuiOutlinedInput-input': { fontWeight: '400!important' }, pr: 1 }}
                onChange={e => handleEditValue(e.target.value || '')}
                maxRows={3}
                type="text"
                fullWidth
                disabled={!isEditing}
                value={billStore.temporaryComments[currentCommentCode].comment}
                multiline
                size={'small'}
              />
            </Grid>
          </Grid>

          {!position && (
            <>
              <Grid container alignItems={'flex-end'} gap={0.5} xs={12}>
                <Typography variant="body2" color="text.secondary" sx={{ paddingTop: 1, paddingLeft: 0 }}>
                  Иные сведения об отгрузке
                </Typography>
                <Badge color="secondary" onMouseEnter={handleTooltipOpen} onMouseLeave={handleTooltipClose}>
                  <InfoIcon fontSize="small" color="action" />
                </Badge>
                <Popper placement="top" open={isTooltipOpen} anchorEl={anchorEl} sx={{ zIndex: 1500 }} transition>
                  {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={150}>
                      <Paper sx={{ p: 1 }}>
                        <Typography variant="body2" color="text.secondary">
                          Дополнительные сведения к документу отгрузка
                        </Typography>
                      </Paper>
                    </Fade>
                  )}
                </Popper>
              </Grid>
              <Grid item xs={12} container alignItems={'center'} wrap={'nowrap'}>
                <Grid item flex={'1 1 auto'}>
                  <TextField
                    sx={{ '& .MuiOutlinedInput-input': { fontWeight: '400!important' }, pr: 1 }}
                    onChange={e => handleEditValueCommentsForShipment(e.target.value || '')}
                    maxRows={3}
                    type="text"
                    fullWidth
                    disabled={!isEditing}
                    value={billStore.temporaryComments['billSummaryForShipment'].comment}
                    multiline
                    size={'small'}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    );
  }
);
