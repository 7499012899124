import { Grid, IconButton, TableCell, TableHead, TableRow } from '@mui/material';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';
import { ProductItemStore } from 'src/store/ProductItemStore';
import { runInAction } from 'mobx';
import { DealItemStore } from 'src/store/DealItemStore';
import { AgreementItemStore } from 'src/store/Clients/AgreementItemStore';
import { NewCatalogStore } from 'src/store/Catalog/NewCatalogStore';

type ArrivalPartyTableHeaderProps = {
  store: ProductItemStore | DealItemStore | AgreementItemStore | NewCatalogStore;
};

export const ArrivalPartyTableHeader: FC<ArrivalPartyTableHeaderProps> = observer(({ store }) => {
  const [isAllArrivalPackOpen, setIsAllArrivalPackOpen] = useState(store?.isAllArrivalPackOpen || false);

  const handleOpenAll = (): void => {
    setIsAllArrivalPackOpen(!isAllArrivalPackOpen);

    runInAction(() => {
      store.isAllArrivalPackOpen = !isAllArrivalPackOpen;
    });
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ whiteSpace: 'nowrap', minWidth: '210px' }}>
          <Grid container justifyContent={'space-between'} alignItems={'center'}>
            Наименование{' '}
            {store && (
              <IconButton onClick={handleOpenAll} size="small" title={isAllArrivalPackOpen ? 'Свернуть все партии' : 'Раскрыть все партии'}>
                <KeyboardDoubleArrowDownIcon
                  sx={{
                    transform: `rotate(${isAllArrivalPackOpen ? 180 : 0}deg)`,
                    transition: 'all .3s ease',
                  }}
                />
              </IconButton>
            )}
          </Grid>
        </TableCell>
        <TableCell>Склад</TableCell>
        <TableCell>Вагон</TableCell>
        <TableCell>Кассета</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap', minWidth: '115px' }}>Вес</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap', minWidth: '115px' }}>Остаток</TableCell>
        <TableCell>Дата поступления</TableCell>
        <TableCell align="right">ТОДХ</TableCell>
        <TableCell align="right">Признак</TableCell>
        <TableCell align="right">Сертификат</TableCell>
      </TableRow>
    </TableHead>
  );
});
