import React, { ChangeEvent, FC, JSX, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { DealItemStore } from '../../../store/DealItemStore';
import { Grid, IconButton, outlinedInputClasses } from '@mui/material';
import Typography from '@mui/material/Typography';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import UTurnLeftIcon from '@mui/icons-material/UTurnLeft';
import TextField from '@mui/material/TextField';
import CheckIcon from '@mui/icons-material/Check';

interface DealValidDaysControlProps {
  dealStore: DealItemStore;
}
const validatorFn = (val: string): boolean => Number(val) < 1 || Number(val) > 30;

export const DealValidDaysControl: FC<DealValidDaysControlProps> = observer(({ dealStore }): JSX.Element => {
  const { useManualBillValidDays, manualBillValidDays, defaultBillValidDays } = dealStore.deal;
  const defaultValue = String(useManualBillValidDays ? manualBillValidDays : defaultBillValidDays);
  const [isChanging, setIsChanging] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [manualBillValidDaysValue, setManualBillValidDaysValue] = useState(`${defaultValue}`);
  const viewOnly = dealStore.isViewOnly();
  const handleTextFieldChanging = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    setHasError(validatorFn(event.target.value));
    setManualBillValidDaysValue(event.target.value);
  };

  const handleBillValidDaysBlur = (): void => {
    if (!hasError) {
      setIsSaving(true);
      setIsChanging(false);

      if (!manualBillValidDays ? +manualBillValidDaysValue !== defaultBillValidDays : +manualBillValidDaysValue !== manualBillValidDays) {
        dealStore
          .updateDeal({
            manualBillValidDays: parseFloat(manualBillValidDaysValue) || 0,
            useManualBillValidDays: parseFloat(manualBillValidDaysValue) !== dealStore.deal.defaultBillValidDays,
          })
          .then(() => {
            setManualBillValidDaysValue(`${manualBillValidDaysValue}`);
          })
          .catch(r => console.warn('handleBillValidDaysBlur', r))
          .finally(() => setIsSaving(false));
      } else {
        setIsSaving(false);
      }
    }
  };

  const toggleChanging = (): void => {
    setIsChanging(true);
  };

  const handleSaveDefaultValue = (): void => {
    setIsSaving(true);
    dealStore
      .updateDeal({
        manualBillValidDays: defaultBillValidDays,
        useManualBillValidDays: false,
      })
      .then(() => {
        setManualBillValidDaysValue(`${defaultBillValidDays}`);
      })
      .catch(r => console.warn('handleSaveDefaultValue', r))
      .finally(() => setIsSaving(false));
  };

  return (
    <Grid container pl={1}>
      <Grid item xs={6} md={8} container alignItems={'center'} justifyContent={'space-between'} flexWrap="nowrap">
        <Typography variant="body2" color={'text.secondary'}>
          Дней активности счёта
        </Typography>
        {hasError && (
          <Typography variant="body2" color={'error'} sx={{ px: '5px' }}>
            от 1 до 30 дн.
          </Typography>
        )}
      </Grid>
      <Grid item xs={6} md={4} container alignItems={'center'}>
        {isChanging ? (
          <Grid container alignItems={'center'} ml={'-3px'}>
            <Grid item maxWidth={'75px'}>
              <TextField
                autoFocus
                type={'number'}
                onChange={handleTextFieldChanging}
                maxRows={1}
                value={manualBillValidDaysValue}
                onBlur={handleBillValidDaysBlur}
                sx={{
                  [`& .${outlinedInputClasses.input}`]: {
                    padding: viewOnly ? '2px 0 1px 1px' : '4px 0 4px 3px',
                    fontWeight: 700,
                    fontSize: '14px',
                    color: hasError && '#C74952',
                  },
                }}
              />
            </Grid>

            <Grid item>
              <IconButton
                size={'small'}
                title={'Сохранить'}
                onClick={handleBillValidDaysBlur}
                disabled={isSaving || dealStore.isViewOnly()}
              >
                <CheckIcon sx={{ fontSize: '18px' }} />
              </IconButton>
            </Grid>
          </Grid>
        ) : (
          <Grid container alignItems={'center'}>
            <Grid item>
              <Typography variant="body2" fontWeight={500} color={'text.secondary'}>
                {manualBillValidDaysValue}
              </Typography>
            </Grid>
            {!viewOnly && (
              <Grid item ml={1}>
                <IconButton
                  size={'small'}
                  title={'Назначить количество дней активности счёта вручную'}
                  onClick={toggleChanging}
                  disabled={isSaving || dealStore.isViewOnly()}
                >
                  <ModeEditIcon sx={{ fontSize: '18px' }} />
                </IconButton>
              </Grid>
            )}
            {dealStore.deal.useManualBillValidDays && (
              <Grid item>
                <IconButton
                  size={'small'}
                  title={'Назначить количество дней активности счёта по умолчанию'}
                  onClick={handleSaveDefaultValue}
                  disabled={isSaving || dealStore.isViewOnly()}
                >
                  <UTurnLeftIcon sx={{ fontSize: '18px', transform: 'rotate(90deg)' }} />
                </IconButton>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
});
