import { makeAutoObservable, runInAction, set, toJS } from 'mobx';
import {
  Address,
  AddressSaveRequest,
  BranchOffice,
  BudgetGroup,
  ClientContract,
  ClientsChangeRequest,
  ClientsChangeRequestsResponse,
  ClientsItemConsigneesResponse,
  ClientsItemMarginalityStatResponse,
  ClientsItemResponse,
  ClientsItemSaveRequest,
  Consignee,
  Contact,
  CreditState,
  CustomerNotDistributedStatItem,
  DocFlowCounteragent,
  DocFlowCounteragentsResponse,
  DocFlowPowerOfAttorneyIncoming,
  Employee,
  EmployeeCustomerMarginalityReport,
  ParticipantDocument,
  ParticipantDocumentSaveResponse,
  ParticipantDocumentsResponse,
  ParticipantDocumentsTypesResponse,
  ParticipantDocumentType,
  ParticipantTimePoint,
  StatPaymentsExpectationsResponse,
  StatPaymentsReceivableReportResponse,
} from '../api/marketx';
import { AxiosResponse } from 'axios';
import { AxiosCallContext, getCallContext } from '../utils/axiosInit';
import { formatDateSwagger, formatDateSwaggerZ, formatDateToISOStringWithTimezone } from '@mx-ui/helpers';
import { ClientType } from '../components/Clients/ClientCard/types';
import { mapClient } from '../views/clients/lib';
import { RootStore } from './StoreManager';
import { ApiStore } from './Global/ApiStore';
import { TimePointsListStore } from './Contacts/TimePointsListStore';
import { ContactsListStore } from './Contacts/ContactsList';
import { InteractionsListStore } from './Interactions/InteractionsListStore';
import { v4 as uuidv4 } from 'uuid';
import { SnackbarStore } from './SnackbarStore';
import { isOfflineError } from '../utils/network';
import { ChangeRequestStore } from './ChangeRequestStore';
import { CustomersByCode, mapReceivableFromTo, mapReceivablePlannedDate } from './ReceivableStatStore';
import { AuthStore } from './AuthStore';
import { RouterStore } from './RouterStore';
import { quickDateRanges, RangeVariant } from 'src/components/SelectDateRange/MenuButtonNew';
import { AttensionForClientStore } from './Clients/AttensionForClientStore';
import { entityType, TopBarEntityStore } from './TopBarStore';
import { ClientRelationshipsStore } from './ClientRelationshipsStore';
import { CustomersPaymentStatType } from './EmployeePaymentStore';

export class ClientTopBarEntityStore implements TopBarEntityStore {
  clientItemStore: ClientItemStore = null;

  constructor(clientItemStore: ClientItemStore) {
    this.clientItemStore = clientItemStore;
    makeAutoObservable(this);
  }

  entityCode(): string {
    return this.clientItemStore.details?.code || '';
  }

  titleForCatalog(): string {
    return '';
  }

  titleForClient(): string {
    return 'Выберите клиента по заявке';
  }

  typeName(): entityType {
    return 'client';
  }

  customerTitle(): string {
    return this.clientItemStore?.details?.shortTitle || this.clientItemStore?.details?.title || '';
  }
}

export type ClientItemRequest = {
  clientCode?: string; // код клиента
  branchOffice?: string; // текущий офис
  month?: Date; // дата, на которую выбрать статистику
  monthDocuments?: Date; // дата, для документов
  tab?: string;
  partnerParticipantCode?: string;
  subtab?: string;
  innerTab?: string;
  quickRange?: string;
  dateFrom?: Date;
  dateTo?: Date;
};

export type UpdateRequest = {
  customerCode: string;
  request: ClientsItemSaveRequest;
};

enum ActionType {
  // save = 'save',
  upload = 'upload',
  deleteFiles = 'deleteFiles',
  // movePosition = 'movePosition',
}

export type Documents = {
  typeCode: string;
  title: string;
  description: string;
  customerCode?: string;
  code?: string;
  file?: File;
};

interface UpdateQueueItem {
  hash: string; // хэш объекта изменений
  properties: string; // хэш названий изменяемых полей
  actionType: ActionType;

  clientCode?: string;
  uploadFiles?: Documents[];
  deleteFilesCodes?: string[];

  onResolve?: (UpdateQueueItem) => void;
  onReject?: (UpdateQueueItem) => void;
}

interface ReceivableRequest {
  plannedDateFrom?: Date;
  plannedDateTo?: Date;
  dateFrom: Date;
  dateTo: Date;
}

// Детали по одному клиенту
export class ClientItemStore {
  clientSvc: ClientItemService;
  apiStore: ApiStore;
  topBarEntityStore: ClientTopBarEntityStore;
  relationshipsStore: ClientRelationshipsStore;
  clientAddresses: Address[] = [];
  quickRange: RangeVariant = quickDateRanges.find(t => t.value === 'current_month');
  paymentQuickRange: RangeVariant = quickDateRanges.find(t => t.value === 'current_month');
  routerStore: RouterStore;
  routerControlEnabled = false;
  authStore: AuthStore;
  snackbarStore: SnackbarStore;
  clientCode: string; // код клиента
  month: Date = null; // загруженный месяц
  request: ClientItemRequest = {};
  updateRequest: UpdateRequest;

  isLoaded = false;

  lastLoadStarted?: Date;

  customersStats: EmployeeCustomerMarginalityReport[] = [];
  customersOffices: BranchOffice[] = [];
  counteragents: DocFlowCounteragent[] = [];
  customerEmployees: Record<string, Employee> = {};
  budgetGroups: Record<string, BudgetGroup> = {};
  // сейчас идет загрузка
  isLoading = true;
  isReceivableLoading = true;
  isPaymentLoading = true;
  isCounterpartyLoading = false;
  isDocFlowPowerOfAttorneyLoading = true;
  consignees: Consignee[] = [];
  timePointsListStore: TimePointsListStore; //* значимые даты и по клиенту и по контактам клиента (все возможные значимые даты по клиенту)
  contactsListStore: ContactsListStore; //* контакты клиента
  interactionsListStore: InteractionsListStore; //* взаимодействия клиента
  changeRequestStore: ChangeRequestStore;
  isLoadedConsignees = false;
  consigneesTotalCount: number;

  details: ClientType = null;
  documents: ParticipantDocument[] = [];
  documentsTypes: ParticipantDocumentType[] = null;
  powersOfAttorneyList: DocFlowPowerOfAttorneyIncoming[] = [];

  clientsChangeRequest: Array<ClientsChangeRequest> = null;
  clientsChangeRequestTotalCount = 0;
  updatesQueue = new Array<UpdateQueueItem>();
  updateDebounceTimeoutDelay = 600;
  private offlineRequestDelay = 9000;
  updateDebounceTimeout: NodeJS.Timeout;
  isSaving = false;
  public reloadRequired = false;

  customersByCode: Record<string, CustomersByCode> = null;
  contractsByCode: Record<string, ClientContract>;
  creditStatesByCodes: Record<string, CreditState>;
  notDistributedStatesByCodes: Record<string, CustomerNotDistributedStatItem>;
  receivablePage = 1;
  receivableRequest: ReceivableRequest = {
    plannedDateFrom: undefined,
    plannedDateTo: undefined,
    dateFrom: new Date(new Date().getFullYear() - 1, 1, -29),
    dateTo: new Date(new Date().getFullYear(), 11, 32),
  };
  canLoadMore = true;
  isAddressLoaded = false;
  customersTotalCount = 0;
  attensionStore: AttensionForClientStore;
  customersPaymentStatList: CustomersPaymentStatType = {};
  private _datePaymentPeriod = {
    from: formatDateToISOStringWithTimezone(new Date(new Date().getFullYear(), new Date().getMonth(), 1)),
    to: formatDateToISOStringWithTimezone(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)),
  };

  set paymentDate({ from, to }) {
    this._datePaymentPeriod.from = formatDateToISOStringWithTimezone(from);
    this._datePaymentPeriod.to = formatDateToISOStringWithTimezone(to);
  }

  constructor(rootStore: RootStore) {
    this.clientSvc = new ClientItemService();
    this.apiStore = rootStore.getApiStore();
    this.snackbarStore = rootStore.getSnackbar();
    this.routerStore = rootStore.getRouter();
    this.relationshipsStore = new ClientRelationshipsStore(rootStore);
    this.timePointsListStore = new TimePointsListStore(rootStore);
    this.interactionsListStore = new InteractionsListStore(rootStore);
    this.changeRequestStore = new ChangeRequestStore(rootStore);
    this.authStore = rootStore.getAuth();
    this.attensionStore = new AttensionForClientStore(this);
    makeAutoObservable(this, {
      clientSvc: false,
      apiStore: false,
      timePointsListStore: false,
      interactionsListStore: false,
      updatesQueue: false,
      snackbarStore: false,
      authStore: false,
    });
  }

  saveClientAddresses(addressData: AddressSaveRequest): void {
    this.apiStore
      .apiClientAddress()
      .addressSave(addressData)
      .then(res => {
        this.setClientAddressesAfterSave([res.data.address], addressData.code);
      })
      .catch(e => {
        console.warn('saveClientAddressesRequest', e);
      });
  }

  deleteAddress(addressCode: string): void {
    this.apiStore
      .apiClientAddress()
      .addressItemDisable(addressCode)
      .then(() => {
        this.setClientAddressesAfterDelete(addressCode);
      })
      .catch(e => {
        console.warn('deleteAddressRequest', e);
      });
  }

  loadClientAddresses(): void {
    this.apiStore
      .apiClientAddress()
      .addressList(this.clientCode)
      .then(res => {
        this.setClientAddresses(res.data.addresses);
      })
      .catch(e => {
        console.warn('loadClientAddressesRequest', e);
      });
  }
  setClientAddressesAfterDelete(addressCode: string): void {
    if (addressCode) {
      this.clientAddresses = [...this.clientAddresses.filter(adr => adr.code !== addressCode)];
    }
  }
  setClientAddressesAfterSave(addresses: Address[], editingCode?: string): void {
    if (editingCode) {
      this.clientAddresses = this.clientAddresses.map(i => {
        if (i.code === editingCode) {
          return addresses[0];
        }
        return i;
      });
    } else {
      this.clientAddresses = [...addresses, ...this.clientAddresses];
    }
  }

  setClientAddresses(addresses: Address[]): void {
    this.clientAddresses = addresses || [];
    this.isAddressLoaded = true;
  }

  loadClientRelationshipsStore(): ClientRelationshipsStore {
    if (this.relationshipsStore) {
      this.relationshipsStore.setCustomerDetails(this.details);
      this.relationshipsStore.loadParticipantRelationshipTypes();
      this.relationshipsStore.loadParticipantRelationshipList(this.details.code);
    }

    const partnerParticipantCode = (this.routerStore.query()?.partnerParticipantCode as string) || '';
    if (partnerParticipantCode || this.relationshipsStore.draftPartnerDetails?.code !== partnerParticipantCode) {
      this.relationshipsStore.loadDraftPartner(partnerParticipantCode);
    }
    return this.relationshipsStore;
  }
  getTopBarEntityStore(): ClientTopBarEntityStore {
    if (!this.topBarEntityStore) {
      this.topBarEntityStore = new ClientTopBarEntityStore(this);
    }
    return this.topBarEntityStore;
  }
  get canSeeClientContacts(): boolean {
    return (
      !!this?.details?.mainEmployee?.code ||
      this.authStore.inAnyGroup(['company_chief', 'division_chief', 'customer_editor', 'division_marketer', 'company_marketer'])
    );
  }
  get documentFiltersAsses(): boolean {
    return this.authStore.inAnyGroup(['company_chief', 'division_chief', 'customer_editor', 'division_marketer', 'company_marketer']);
  }
  get isDocumentFiltersHidden(): boolean {
    return (
      this.authStore.inAnyGroup(['seller', 'seller_chief']) &&
      !this.authStore.inAnyGroup([
        'company_chief',
        'division_chief',
        'customer_editor',
        'division_marketer',
        'company_marketer',
        'office_chief',
      ])
    );
  }
  get clientTimePoints(): ParticipantTimePoint[] {
    return this.timePointsListStore.items.filter(i => !i.contactCode);
  }
  get contactClient(): Contact {
    //* item контакта, который является самим клиентом
    return this.contactsListStore?.contacts?.find(i => i.isCompany);
  }
  setContactListStore(store?: ContactsListStore): void {
    this.contactsListStore = store;
  }

  async loadDocFlowCounterparty(clientCode: string): Promise<void> {
    if (!clientCode) {
      return;
    }
    runInAction(() => {
      this.isLoading = true;
    });
    try {
      const res = await this.apiStore.apiDocFlow().docFlowCounteragents(clientCode);
      this.setResultFlowCounterparty(res.data);
    } catch (err) {
      console.warn('docFlowCounteragents error', err);
    }
  }

  setResultFlowCounterparty(data: DocFlowCounteragentsResponse): void {
    this.counteragents = data.counteragents;
    runInAction(() => {
      this.isCounterpartyLoading = false;
    });
  }
  refreshCounterparty(): void {
    runInAction(() => {
      this.isCounterpartyLoading = true;
    });
    this.apiStore
      .apiDocFlow()
      .docFlowCounteragentsRefresh({ customerCode: this.clientCode })
      .then(res => {
        this.setResultFlowCounterparty(res.data);
      })
      .catch(err => {
        console.warn('refreshCounterpartyReport error', err);
      });
  }

  loadClientDocFlowPowerOfAttorney(branchOfficeCodes: string[]): Promise<void> {
    runInAction(() => {
      this.isDocFlowPowerOfAttorneyLoading = true;
    });
    return this.apiStore
      .apiDocFlow()
      .docFlowPowerOfAttorneyIncomingList({
        customerCodes: [this.clientCode],
        branchOfficeCodes: branchOfficeCodes.length ? branchOfficeCodes : undefined,
      })
      .then(res =>
        runInAction(() => {
          this.powersOfAttorneyList = res.data.powerOfAttorneys.sort(
            (a, b) => new Date(b.documentDate).valueOf() - new Date(a.documentDate).valueOf()
          );
        })
      )
      .catch(e => console.warn('loadClientDocFlowPowerOfAttorney error', e))
      .finally(() =>
        runInAction(() => {
          this.isDocFlowPowerOfAttorneyLoading = false;
        })
      );
  }

  setDate(dateFrom: Date, dateTo: Date): void {
    this.paymentDate = { from: dateFrom, to: dateTo };
  }
  get paymentDateSwagger(): { from: string; to: string } {
    return {
      from: formatDateSwagger(this._datePaymentPeriod.from),
      to: formatDateSwagger(this._datePaymentPeriod.to),
    };
  }
  loadClientPayment(clientCode: string): void {
    const currentDate = this._datePaymentPeriod;
    this.isPaymentLoading = true;
    this.apiStore
      .apiStatApi()
      .statPaymentsExpectations(
        // {
        //   branchOfficeCodes: [this.authStore.profile.chosenBranchOfficeCode || this.authStore.profile.branchOfficeCode],
        //   customerCode: clientCode,
        //   from: currentDate.from,
        //   to: currentDate.to,
        undefined,
        clientCode,
        undefined,
        undefined,
        currentDate.from,
        currentDate.to
      )
      .then(res => {
        this.setPaymentStats(res.data);
      })
      .catch(e => {
        console.warn('statPaymentsPlanned', e);
        this.isPaymentLoading = false;
      });
  }
  setPaymentStats(data: StatPaymentsExpectationsResponse): void {
    if (!data.customers?.length) {
      this.customersPaymentStatList = {};
      this.isPaymentLoading = false;
      return;
    }

    const result: CustomersPaymentStatType = {};
    const customer = data.customers[0];
    result[customer.code] = {
      totalAwaitingAmount: 0,
      totalOverdueAmount: 0,
      totalPaidAmount: 0,
      shortTitle: customer?.shortTitle || customer?.title,
      mainEmployee: `${customer?.mainEmployee?.surname || ''} ${customer?.mainEmployee?.name || ''} ${
        customer?.mainEmployee?.patronymic || ''
      }`,
      companyFoundedDate: customer?.mainEmployeeJoinDate,
      notDistributedAmount: 0,
      bills: {},
    };

    data.stats.forEach(stat => {
      if (result[stat.customerCode]) {
        result[stat.customerCode].totalPaidAmount += stat?.paidAmount || 0;
        result[stat.customerCode].totalOverdueAmount += stat?.overdueAmount || 0;
        result[stat.customerCode].totalAwaitingAmount += stat?.awaitingAmount || 0;
        if (result[stat.customerCode].bills[stat.billMdmCode]) {
          result[stat.customerCode].bills[stat.billMdmCode].push(stat);
        } else {
          result[stat.customerCode].bills[stat.billMdmCode] = [stat];
        }
      }
    });
    this.customersPaymentStatList[customer.code] = result[customer.code];
    this.isPaymentLoading = false;
  }
  setReceivableRequestFromObj(clientCode: string, req: ReceivableRequest): void {
    set(this.receivableRequest, req);
    this.loadClientReceivable(clientCode);
  }
  async loadClientReceivable(clientCode: string): Promise<void> {
    if (!clientCode) {
      return;
    }
    const { plannedDateFrom, plannedDateTo, dateTo, dateFrom } = this.receivableRequest;
    runInAction(() => {
      this.isReceivableLoading = true;
    });
    try {
      const res = await this.apiStore.apiStatApi().statPaymentsReceivableReport({
        dateFrom: formatDateSwaggerZ(dateFrom),
        dateTo: formatDateSwaggerZ(dateTo),
        plannedDateFrom: plannedDateFrom ? formatDateSwaggerZ(plannedDateFrom) : undefined,
        plannedDateTo: plannedDateTo ? formatDateSwaggerZ(plannedDateTo) : undefined,
        page: this.receivablePage,
        count: 20,
        customerCode: clientCode,
        branchOfficeCodes: [this.authStore.profile.chosenBranchOfficeCode || this.authStore.profile.branchOfficeCode],
      });
      this.setResultReceivableEmployees(res);
    } catch (err) {
      console.warn('statPaymentsReceivableReport error', err);
    }
  }
  setRouterControl(enabled: boolean): void {
    this.routerControlEnabled = enabled;
  }
  changeQuickRange(v: RangeVariant): void {
    this.quickRange = v;
    set(this.request, { quickRange: v.value });
  }
  changePaymentQuickRange(v: RangeVariant): void {
    this.paymentQuickRange = v;
    set(this.request, { quickRange: v.value });
  }
  setResultReceivableEmployees(data: AxiosResponse<StatPaymentsReceivableReportResponse>): void {
    if (this.receivablePage === 1) {
      this.customersByCode = {};
      this.contractsByCode = {};
      this.creditStatesByCodes = {};
      this.notDistributedStatesByCodes = {};
    }
    if (data.data.customers?.length) {
      data.data.customers.forEach(customer => {
        this.customersByCode[customer.code] = {
          customer: { ...customer },
          awaitingAmount: 0, // ДЗ
          overdueAmount: 0, // ПДЗ
          notDistributed: 0,
          totalLoanAmount: 0,
          customerDaysLeft: { from: 0, to: 0 },
          customerDaysOverdue: { from: 0, to: 0 },
          plansByContractCode: {},
          customerPlannedDate: '',
          otherPlans: {
            plans: [],
            awaitingAmount: 0,
            overdueAmount: 0,
            daysOverduePeriod: new Set(),
          },
        };
      });
    }
    if (data.data?.contracts?.length) {
      data.data?.contracts?.forEach(item => {
        this.contractsByCode[item.code] = item;
      });
    }

    if (data.data?.creditStates?.length) {
      data.data?.creditStates?.forEach(state => {
        this.creditStatesByCodes[state.branchOfficeCode + state.customerCode] = state;
      });
    }

    if (data.data?.notDistributed?.length) {
      data.data?.notDistributed?.forEach(state => {
        this.notDistributedStatesByCodes[state.branchOfficeCode + state.customerCode] = state;
      });
    }

    if (data.data.plans?.length) {
      data.data.plans?.forEach(plan => {
        // если в плане есть customerCode и такой customer пришел
        if (plan?.customerCode && this.customersByCode[plan?.customerCode]) {
          // если в плане есть код договора
          if (plan?.contractCode) {
            // договор уже добавлен
            if (this.customersByCode[plan?.customerCode]?.plansByContractCode[plan?.contractCode]) {
              // в плане есть код счета
              if (plan?.billMdmCode) {
                // счет уже добавлен
                if (this.customersByCode[plan?.customerCode]?.plansByContractCode[plan?.contractCode].byBillCode[plan?.billMdmCode]) {
                  this.customersByCode[plan?.customerCode]?.plansByContractCode[plan?.contractCode].byBillCode[
                    plan?.billMdmCode
                  ].shipments.push(plan);
                } else {
                  // счет еще не добавлен
                  this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].byBillCode[plan?.billMdmCode] = {
                    shipments: [],
                    awaitingAmount: 0,
                    overdueAmount: 0,
                    billDaysLeft: { from: 0, to: 0 },
                    billDaysOverdue: { from: 0, to: 0 },
                    billPlannedDate: '',
                  };
                  this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].byBillCode[plan?.billMdmCode].shipments =
                    [plan];
                }
                this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].byBillCode[
                  plan?.billMdmCode
                ].awaitingAmount += plan?.awaitingAmount || 0;
                this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].byBillCode[
                  plan?.billMdmCode
                ].overdueAmount += plan?.overdueAmount || 0;
                this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].byBillCode[
                  plan?.billMdmCode
                ].billDaysLeft = mapReceivableFromTo(
                  this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].byBillCode[plan?.billMdmCode]
                    .billDaysLeft,
                  plan.daysLeft || 0
                );
                this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].byBillCode[
                  plan?.billMdmCode
                ].billDaysOverdue = mapReceivableFromTo(
                  this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].byBillCode[plan?.billMdmCode]
                    .billDaysOverdue,
                  plan.daysOverdue || 0
                );
                this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].byBillCode[
                  plan?.billMdmCode
                ].billPlannedDate = mapReceivablePlannedDate(
                  this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].byBillCode[plan?.billMdmCode]
                    .billPlannedDate,
                  plan.plannedDate
                );
              } else {
                // в плане нет кода счета
                this.customersByCode[plan?.customerCode]?.plansByContractCode[plan?.contractCode].shipmentCodeOnly.push(plan);
              }
              // считаем сумму ДЗ и ПДЗ по договору
              this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].awaitingAmount += plan?.awaitingAmount || 0;
              this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].overdueAmount += plan?.overdueAmount || 0;
              this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].contractLoanAmount += plan.loanAmount || 0;
              this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].maxInterestRatePct =
                plan.maxInterestRatePct || 0;
              this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].contractDaysLeft = mapReceivableFromTo(
                this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].contractDaysLeft,
                plan.daysLeft || 0
              );
              this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].contractPlannedDate =
                mapReceivablePlannedDate(
                  this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].contractPlannedDate,
                  plan.plannedDate
                );
            } else {
              // договор еще не добавлен
              this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode] = {
                byBillCode: {},
                shipmentCodeOnly: [],
                awaitingAmount: 0, // ДЗ
                overdueAmount: 0, // ПДЗ
                daysOverduePeriod: new Set(),
                contractLoanAmount: 0,
                maxInterestRatePct: 0,
                contractDaysLeft: { from: 0, to: 0 },
                contractPlannedDate: '',
              };
              // в плане есть код счета
              if (plan?.billMdmCode) {
                // счет уже создан
                if (this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].byBillCode[plan?.billMdmCode]) {
                  this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].byBillCode[
                    plan?.billMdmCode
                  ].shipments.push(plan);
                } else {
                  this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].byBillCode[plan?.billMdmCode] = {
                    shipments: [],
                    awaitingAmount: 0,
                    overdueAmount: 0,
                    billDaysLeft: { from: 0, to: 0 },
                    billDaysOverdue: { from: 0, to: 0 },
                    billPlannedDate: '',
                  };
                  this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].byBillCode[plan?.billMdmCode].shipments =
                    [plan];
                }
                this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].byBillCode[
                  plan?.billMdmCode
                ].awaitingAmount += plan?.awaitingAmount || 0;
                this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].byBillCode[
                  plan?.billMdmCode
                ].overdueAmount += plan?.overdueAmount || 0;
                this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].byBillCode[
                  plan?.billMdmCode
                ].billDaysLeft = mapReceivableFromTo(
                  this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].byBillCode[plan?.billMdmCode]
                    .billDaysLeft,
                  plan.daysLeft || 0
                );
                this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].byBillCode[
                  plan?.billMdmCode
                ].billDaysOverdue = mapReceivableFromTo(
                  this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].byBillCode[plan?.billMdmCode]
                    .billDaysOverdue,
                  plan.daysOverdue || 0
                );
                this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].byBillCode[
                  plan?.billMdmCode
                ].billPlannedDate = mapReceivablePlannedDate(
                  this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].byBillCode[plan?.billMdmCode]
                    .billPlannedDate,
                  plan.plannedDate
                );
              } else {
                this.customersByCode[plan?.customerCode]?.plansByContractCode[plan?.contractCode].shipmentCodeOnly.push(plan);
              }
              // считаем сумму ДЗ и ПДЗ по договору
              this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].awaitingAmount += plan?.awaitingAmount || 0;
              this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].overdueAmount += plan?.overdueAmount || 0;
              this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].contractLoanAmount += plan.loanAmount || 0;
              this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].maxInterestRatePct =
                plan.maxInterestRatePct || 0;
              this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].contractDaysLeft = mapReceivableFromTo(
                this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].contractDaysLeft,
                plan.daysLeft || 0
              );
              this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].contractPlannedDate =
                mapReceivablePlannedDate(
                  this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].contractPlannedDate,
                  plan.plannedDate
                );
            }
          } else {
            // в плане нет кода договора
            this.customersByCode[plan?.customerCode].otherPlans.awaitingAmount += plan?.awaitingAmount || 0;
            this.customersByCode[plan?.customerCode].otherPlans.overdueAmount += plan?.overdueAmount || 0;
            this.customersByCode[plan?.customerCode].otherPlans.plans.push(plan);
          }
          // находим все значения просрочки
          if (plan?.contractCode) {
            plan.daysOverdue &&
              this.customersByCode[plan?.customerCode]?.plansByContractCode[plan?.contractCode].daysOverduePeriod.add(plan.daysOverdue);
          } else {
            plan.daysOverdue && this.customersByCode[plan?.customerCode].otherPlans.daysOverduePeriod.add(plan.daysOverdue);
          }
          this.customersByCode[plan?.customerCode].customerDaysLeft = mapReceivableFromTo(
            this.customersByCode[plan?.customerCode].customerDaysLeft,
            plan?.daysLeft || 0
          );
          this.customersByCode[plan?.customerCode].customerDaysOverdue = mapReceivableFromTo(
            this.customersByCode[plan?.customerCode].customerDaysOverdue,
            plan?.daysOverdue || 0
          );
          this.customersByCode[plan?.customerCode].customerPlannedDate = mapReceivablePlannedDate(
            this.customersByCode[plan?.customerCode].customerPlannedDate,
            plan?.plannedDate
          );
        }
        // прибавляем ПДЗ, ДЗ, Стоимость (13,87%/год)
        this.customersByCode[plan?.customerCode].awaitingAmount += plan?.awaitingAmount || 0;
        this.customersByCode[plan?.customerCode].overdueAmount += plan?.overdueAmount || 0;
        this.customersByCode[plan?.customerCode].totalLoanAmount += plan.loanAmount || 0;
      });
    }
    this.customersTotalCount = data.data?.customers?.length ?? 0;
    this.canLoadMore = this.customersTotalCount > Object.keys(this.customersByCode).length;
    runInAction(() => {
      this.isReceivableLoading = false;
    });
  }
  load(req: ClientItemRequest): void {
    runInAction(() => {
      // this.mergeRequest(req);
      if (!this.clientCode && req.clientCode) {
        this.clientCode = req.clientCode;
      }
      if (!this.isLoaded && req.month) {
        this.month = req.month;
      }
      this.clientSvc.executeOne(this);
      this.attensionStore.setClientCode(this.clientCode);
      this.clientSvc.loadCustomerStats(this);
      this.clientSvc.loadConsignees(this);
      this.clientSvc.loadClientDocuments(this);
      this.attensionStore.loadAttentionList();
      this.attensionStore.loadAttentionReasons();
      this.clientSvc.loadClientDocumentsTypes(this);
      if (this.request.branchOffice) {
        this.clientSvc.loadClientChangeRequests(this);
      }
    });
  }
  reloadClient(): void {
    this.clientSvc.executeOne(this);
  }
  setClientSource(data: UpdateRequest): Promise<void> {
    runInAction(() => {
      this.updateRequest = data;
    });
    return this.clientSvc.updateCustomer(this);
  }
  // согласования заявки на доступ и параметры заявки
  setChangeRequestsResult(ctx: AxiosCallContext, res: AxiosResponse<ClientsChangeRequestsResponse>): void {
    runInAction(() => {
      const data = res.data?.changeRequests.filter(item => item.stateCode !== 'applied') || [];
      if (data.length) {
        const requestList = [...data];
        this.clientsChangeRequest = requestList.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
      } else {
        this.clientsChangeRequest = data;
      }
      this.clientsChangeRequestTotalCount = res.data.totalCount;
      this.isLoading = false;
    });
  }

  setNewClientDocuments(docs: Array<any>): void {
    runInAction(() => {
      this.isLoading = true;
    });

    const updItem = <UpdateQueueItem>{
      hash: uuidv4(),
      properties: uuidv4(),

      actionType: ActionType.upload,

      clientCode: this.clientCode,
      uploadFiles: docs,
    };
    let text = 'Файлы успешно загружены';
    if (docs.length === 1) {
      text = 'Файл успешно загружен';
    }
    updItem.onResolve = () => {
      this.snackbarStore.showInfo(text);
    };
    this.updatesQueue.push(updItem);
    this.updateQueueDebounce(this.updateDebounceTimeoutDelay);
  }

  deleteClientDoc(codes: Array<string>): void {
    if (!codes.length) {
      return;
    }
    runInAction(() => {
      this.isLoading = true;
    });
    const updItem = <UpdateQueueItem>{
      hash: uuidv4(),
      properties: uuidv4(),
      actionType: ActionType.deleteFiles,

      deleteFilesCodes: codes,
    };
    let text = 'Файлы успешно удалены';
    if (codes.length === 1) {
      text = 'Файл успешно удален';
    }
    updItem.onResolve = () => this.snackbarStore.showInfo(text);
    this.updatesQueue.push(updItem);
    this.updateQueueDebounce(this.updateDebounceTimeoutDelay);
  }

  updateQueueDebounce(TimeoutDelay: number): void {
    if (this.updateDebounceTimeout) {
      clearTimeout(this.updateDebounceTimeout);
    }
    this.updateDebounceTimeout = setTimeout(() => {
      this.updateQueueExec();
    }, TimeoutDelay);
  }

  updateQueueExec(): void {
    if (this.isSaving) {
      // конкурирующий процесс?
      return;
    }
    if (!this.updatesQueue.length) {
      // нет изменений
      return;
    }
    const upd: UpdateQueueItem = this.updatesQueue[0];
    runInAction(() => {
      this.isSaving = true;
    });
    const actionPromise: any[] = [];
    // setDocumentsResult
    switch (upd.actionType) {
      case ActionType.upload: {
        const promise = upd.uploadFiles.map(file => this.clientSvc.setClientDocuments(this, file));
        actionPromise.push(promise);
        break;
      }
      case ActionType.deleteFiles: {
        const promise = upd.deleteFilesCodes.map(code => this.clientSvc.deleteClientDocuments(this, code));
        actionPromise.push(promise);
        break;
      }
      default: {
        actionPromise.push(new Promise((success, reject) => reject('unknown actionType ' + upd.actionType)));
      }
    }
    Promise.all(actionPromise)
      .then(() => {
        runInAction(() => {
          this.updatesQueue = this.updatesQueue.filter(q => q !== upd);
          this.isSaving = false;
        });
        if (upd.onResolve) {
          upd.onResolve(upd);
        }
        this.updateQueueDebounce(1);
      })
      .catch(error => {
        console.warn('Error in ClientItemStore updateQueueExec', error);
        // обработка исключения возникшего во время выполнения задачи.
        runInAction(() => {
          this.isSaving = false;
        });
        if (isOfflineError(error)) {
          // Если ошибка сети, то повторяем запрос через заданный интервал, не очищая очередь.
          clearTimeout(this.updateDebounceTimeout);
          this.updateQueueDebounce(this.offlineRequestDelay);
        } else {
          if (upd.onReject) {
            upd.onReject(upd);
          } else {
            console.warn(error, upd);
          }

          // Если ошибка в логике приложения, то исключаем задачу из очереди и запускаем очередь со следующей задачи.
          runInAction(() => {
            this.updatesQueue = this.updatesQueue.filter(q => q !== upd);
          });
          clearTimeout(this.updateDebounceTimeout);
          this.updateQueueDebounce(1);
        }
        this.setReloadRequired(true);
      });
  }
  setReloadRequired(required: boolean): void {
    this.reloadRequired = required;
  }
  setNewDocumentsResult(ctx: AxiosCallContext, res: AxiosResponse<ParticipantDocumentSaveResponse>): void {
    this.documents.splice(this.documents.length - 1, 0, res.data.ParticipantDocument);
    this.isLoading = false;
  }
  setResultAfterDelete(code: string): void {
    runInAction(() => {
      const deleteIndex = this.documents.findIndex(item => item.code === code);
      this.documents.splice(deleteIndex, 1);
      this.isLoading = false;
    });
  }
  setDocumentsResult(ctx: AxiosCallContext, res: AxiosResponse<ParticipantDocumentsResponse>): void {
    runInAction(() => {
      this.documents = res.data.ParticipantDocuments;
      this.isLoading = false;
    });
  }

  loadClientMonth(clientCode: string, date: Date, branchOffice?: string): void {
    this.load(<ClientItemRequest>{
      clientCode: clientCode,
      branchOffice,
      month: date,
      monthDocuments: date,
    });
  }
  setMonthForTargets(month: Date): void {
    runInAction(() => {
      this.mergeRequest({
        clientCode: this.request.clientCode,
        month: month,
      });

      this.clientSvc.executeOne(this, month);
      this.clientSvc.loadCustomerStats(this);
    });
  }

  setPeriodForDocuments(dateFrom: Date | undefined, dateTo: Date | undefined): void {
    runInAction(() => {
      this.mergeRequest({
        clientCode: this.request.clientCode,
        monthDocuments: undefined,
        dateFrom,
        dateTo,
      });
    });
  }
  // Перезагрузить данные на указанный месяц
  // setMonth(month: Date): void {
  //   this.load(<ClientItemRequest>{
  //     clientCode: this.request.clientCode,
  //     month: month,
  //   });
  // }
  mergeRequest(req: ClientItemRequest): void {
    set(this.request, req);
    this.actualizeRouter(toJS(this.request));
  }

  setRequestByParams(params: Record<string, any>): ClientItemRequest {
    const req: ClientItemRequest = {};
    if (params['tab']) {
      req.tab = params['tab'];
    }
    if (params['subtab']) {
      req.subtab = params['subtab'];
    }
    if (params['innerTab']) {
      req.innerTab = params['innerTab'];
    }
    if (params['clientCode']) {
      req.clientCode = params['clientCode'];
    }
    if (params['quickRange']) {
      req.quickRange = params['quickRange'];
    }
    if (params['partnerParticipantCode']) {
      req.partnerParticipantCode = params['partnerParticipantCode'];
    }
    if (params['dateTo']) {
      req.dateTo = params['dateTo'] as Date;
    }
    if (params['month']) {
      req.month = params['month'] as Date;
    }
    if (params['dateFrom']) {
      req.dateFrom = params['dateFrom'] as Date;
    }
    runInAction(() => {
      if (req.quickRange) {
        this.quickRange = quickDateRanges.find(t => t.value === req.quickRange);
      }
    });

    this.mergeRequest(req);
    return this.request;
  }
  actualizeRouter(r: ClientItemRequest): void {
    const req = toJS(r || this.request);
    if (!this.routerControlEnabled) {
      return;
    }
    const params = new URLSearchParams();

    if (req.tab) {
      params.set('tab', req.tab);
    }
    if (req.month) {
      params.set('month', formatDateSwagger(req.month));
    }
    if (req.subtab) {
      params.set('subtab', req.subtab);
    }
    if (req.partnerParticipantCode) {
      params.set('partnerParticipantCode', req.partnerParticipantCode);
    }
    if (req.innerTab) {
      params.set('innerTab', req.innerTab);
    }
    if (req.quickRange) {
      params.set('quickRange', req.quickRange);
    }
    if (req.dateFrom) {
      params.set('dateFrom', formatDateSwagger(req.dateFrom));
    }
    if (req.dateTo) {
      params.set('dateTo', formatDateSwagger(req.dateTo));
    }
    // if (req.deal) {
    //   params.set('deal', req.deal);
    // }
    // if (req.rating && req.rating.length > 0) {
    //   params.set('rating', req.rating.join(','));
    // }
    // if (req.statuses && req.statuses.length > 0) {
    //   params.set('statuses', req.statuses.join(','));
    // }
    // if (req.employeeSetCode && req.employeeSetCode !== reqInit.employeeSetCode) {
    //   params.set('employeeSet', req.employeeSetCode);
    // }
    // if (req.phone) {
    //   params.set('phone', req.phone);
    // }

    // params.set('csid', String(this.storeIdentifier));
    let paramsStr = params.toString();
    if (paramsStr) {
      paramsStr = '?' + paramsStr;
    }
    let url = `/app/clients/${this.clientCode || req.clientCode}`;
    url += paramsStr;
    this.routerStore.replace(url, undefined, { shallow: true });
  }
  setResult(ctx: AxiosCallContext, req: ClientItemRequest, res: ClientsItemResponse): void {
    const details = mapClient(res.customer);
    const currentRelationships = details?.relationships?.filter(r => r.branchOfficeCode === this.authStore.profile.chosenBranchOfficeCode);
    details.relationships = currentRelationships?.length ? currentRelationships : undefined;
    this.details = details;
    this.month = req.month;
    this.isLoaded = true;
    this.isLoading = false;
  }
  setResultStats(ctx: AxiosCallContext, { data }: AxiosResponse<ClientsItemMarginalityStatResponse>): void {
    this.isLoading = false;
    const budgetGroups: BudgetGroup[] = data.budgetGroups || [];
    this.budgetGroups = budgetGroups.reduce<Record<string, BudgetGroup>>((acc, i) => {
      acc[i.code] = i;
      return acc;
    }, {});
    this.customersStats = data.stats || [];
    this.customersOffices = data.offices || [];

    this.customerEmployees = [...(data.employees || [])].reduce<Record<string, Employee>>((acc, i) => {
      acc[i.code] = i;
      return acc;
    }, {});
  }
  setDocumentsTypesResult(ctx: AxiosCallContext, res: AxiosResponse<ParticipantDocumentsTypesResponse>): void {
    runInAction(() => {
      this.documentsTypes = res.data.ParticipantDocumentTypes;
      this.isLoading = false;
    });
  }
  setResultConsignees(ctx: AxiosCallContext, { data }: AxiosResponse<ClientsItemConsigneesResponse>): void {
    runInAction(() => {
      this.consignees = data.consignees || [];
      this.consigneesTotalCount = data.consigneesTotalCount;
      this.isLoadedConsignees = true;
      this.isLoading = false;
    });
  }
  setSourceResult(ctx: AxiosCallContext, res: ClientsItemResponse): void {
    runInAction(() => {
      this.details = mapClient(res.customer);
    });
  }
  changeContactStatus(hasDocuments: boolean): void {
    this.details.lastSendingStatusTitle = 'В очереди';
    this.details.lastSendingStatusCode = 'sent';
    if (hasDocuments) {
      this.clientSvc.loadClientDocuments(this);
    }
  }
}

class ClientItemService {
  loadClientChangeRequests(store: ClientItemStore): Promise<void> {
    runInAction(() => {
      store.isLoading = true;
    });
    return store.apiStore
      .apiClientCustomer()
      .clientsChangeRequests(
        store.request.clientCode || store.clientCode,
        store.authStore.profile.chosenBranchOfficeCode,
        undefined,
        'office_access',
        undefined,
        undefined,
        99
      )
      .then(res => {
        store.setChangeRequestsResult(getCallContext(res), res);
      });
  }
  loadClientDocuments(store: ClientItemStore): Promise<void> {
    runInAction(() => {
      store.isLoading = true;
    });
    return store.apiStore
      .apiClientParticipant()
      .participantDocuments(undefined, store.request.clientCode || store.clientCode)
      .then(res => {
        store.setDocumentsResult(getCallContext(res), res);
      });
  }
  loadClientDocumentsTypes(store: ClientItemStore): Promise<void> {
    runInAction(() => {
      store.isLoading = true;
    });
    return store.apiStore
      .apiClientParticipant()
      .participantDocumentTypes()
      .then(res => {
        store.setDocumentsTypesResult(getCallContext(res), res);
      });
  }
  setClientDocuments(store: ClientItemStore, files: Documents): Promise<void> {
    return store.apiStore
      .apiClientParticipant()
      .participantDocumentSave(files.typeCode, files.title, files?.code, files.description, files?.file, undefined, files.customerCode)
      .then(res => {
        store.setNewDocumentsResult(getCallContext(res), res);
      });
  }
  deleteClientDocuments(store: ClientItemStore, code: string): Promise<void> {
    return store.apiStore
      .apiClientParticipant()
      .participantDocumentsItemDelete(code)
      .then(() => {
        store.setResultAfterDelete(code);
      });
  }
  updateCustomer(store: ClientItemStore): Promise<void> {
    runInAction(() => {
      store.isLoading = true;
    });
    return store.apiStore
      .apiClientCustomer()
      .clientsItemSave(store.updateRequest.customerCode, store.updateRequest.request)
      .then(res => {
        store.setSourceResult(getCallContext(res), res.data);
      });
  }
  loadCustomerStats(store: ClientItemStore): void {
    runInAction(() => {
      store.isLoading = true;
    });
    const req = Object.assign({}, store.request);
    req.month = req.month || undefined;
    store.apiStore
      .apiClientCustomer()
      .clientsItemMarginalityStat(store.request.clientCode || store.clientCode, req.month ? formatDateSwaggerZ(req.month) : undefined)
      .then((res: AxiosResponse<ClientsItemMarginalityStatResponse>): void => {
        store.setResultStats(getCallContext(res), res);
      })
      .catch(err => {
        console.warn('ClientsItemMarginalityStatRequest', err);
      });
  }
  loadConsignees(store: ClientItemStore): void {
    runInAction(() => {
      store.isLoading = true;
    });
    store.apiStore
      .apiClientCustomer()
      .clientsItemConsignees(store.request.clientCode || store.clientCode)
      .then((res: AxiosResponse<ClientsItemConsigneesResponse>): void => {
        store.setResultConsignees(getCallContext(res), res);
      })
      .catch(err => {
        console.warn('loadConsigneesRequest', err);
      });
  }
  executeOne(store: ClientItemStore, month?: Date): void {
    const lastLoadStarted = new Date();
    runInAction(() => {
      store.lastLoadStarted = lastLoadStarted;
      store.isLoading = true;
    });
    const req = Object.assign({}, store.request);
    const monthReq = month ?? req.month ?? undefined;
    store.apiStore
      .apiClientCustomer()
      .clientsItem(store.request.clientCode || store.clientCode, monthReq ? formatDateSwaggerZ(monthReq) : undefined)
      .then((res: AxiosResponse<ClientsItemResponse>): void => {
        if (lastLoadStarted == store.lastLoadStarted) {
          store.setResult(getCallContext(res), req, res.data);
        }
      })
      .catch(e => {
        console.warn('executeOneRequest', e);
      });
  }
}
