import { Divider, Grid, IconButton, Link, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { DialogContainer } from 'src/components/ui/dialogs/DialogContainer';
import { ItemPack } from 'src/views/catalog/Listing/ProductPackTable';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import { formatPriceCur } from '@mx-ui/helpers';

type ArrivalPartyInfoDialogProps = {
  isDialogOpen: boolean;
  onClose: () => void;
  batch: ItemPack;
};

export const ArrivalPartyInfoDialog: FC<ArrivalPartyInfoDialogProps> = observer(({ isDialogOpen, onClose, batch }) => {
  return (
    <DialogContainer
      open={isDialogOpen}
      onClose={onClose}
      fullWidth
      canselBtnText="Закрыть"
      dialogContent={
        <Grid item>
          <Grid container alignItems="center" gap={1}>
            <IconButton disabled sx={{ pl: 0 }}>
              <WidgetsOutlinedIcon />
            </IconButton>
            <Typography fontSize={'16px'} color="text.secondary">
              {batch.batchNumber}
            </Typography>
            <Link
              href={`/app/batches/${batch.batchNumber}`}
              sx={{ textTransform: 'uppercase', fontSize: '16px' }}
              underline="none"
              target="_blank"
            >
              {'К партии >>'}
            </Link>
          </Grid>
          <Divider sx={{ mb: 1 }} />

          {batch.purchaseUnitCost > 0 && (
            <Grid container justifyContent={'space-between'}>
              <Grid item>
                <Typography variant={'body1'}>Цена закупки</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant={'body1'}>{formatPriceCur(batch?.purchaseUnitCost || 0)}</Typography>
              </Grid>
            </Grid>
          )}

          {batch.tpcUnitCost > 0 && (
            <Grid container justifyContent={'space-between'}>
              <Grid item>
                <Typography variant={'body1'}>ТЗР</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant={'body1'}>{formatPriceCur(batch?.tpcUnitCost || 0, '₽/' + (batch?.unitShortName ?? 'т'))}</Typography>
              </Grid>
            </Grid>
          )}

          {batch.lwhUnitCost > 0 && (
            <Grid container justifyContent={'space-between'}>
              <Grid item>
                <Typography variant={'body1'}>Лог-скл. затраты</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant={'body1'}>{formatPriceCur(batch?.lwhUnitCost || 0, '₽/' + (batch?.unitShortName ?? 'т'))}</Typography>
              </Grid>
            </Grid>
          )}

          {batch.financialUnitCost > 0 && (
            <Grid container justifyContent={'space-between'}>
              <Grid item>
                <Typography variant={'body1'}>Фин. затраты</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant={'body1'}>
                  {formatPriceCur(batch?.financialUnitCost || 0, '₽/' + (batch?.unitShortName ?? 'т'))}
                </Typography>
              </Grid>
            </Grid>
          )}

          {(batch.purchaseUnitCost > 0 || batch.tpcUnitCost > 0 || batch.lwhUnitCost > 0 || batch.financialUnitCost > 0) && (
            <Divider sx={{ mb: 1, mt: 1 }} />
          )}

          <Grid container justifyContent={'space-between'}>
            <Grid item>
              <Typography variant={'body1'}>Параметр</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant={'body1'}>{batch?.parameter || '-'}</Typography>
            </Grid>
          </Grid>

          <Grid container justifyContent={'space-between'}>
            <Grid item>
              <Typography variant={'body1'}>Упаковка</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant={'body1'}>{batch?.boxingNumber || '-'}</Typography>
            </Grid>
          </Grid>
        </Grid>
      }
    />
  );
});
