import { useEffect, useState, JSX } from 'react';
import ruLocale from 'date-fns/locale/ru';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateRange } from './DateRange';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { getNormalizedDateRange } from '@mx-ui/helpers';
import { DialogContainer } from '../ui/dialogs/DialogContainer';
import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';
interface ModalOnCloseCallback {
  (): void;
}

type ModalProps = {
  isOpen?: boolean;
  canReset?: boolean;
  onClose?: ModalOnCloseCallback;
  onSave?: any;
  text?: string;
  startDateDefault?: Date | null;
  endDateDefault?: Date | null;
};

type ModalErrors = {
  from?: string;
  to?: string;
};

const Modal = ({
  isOpen,
  onClose,
  onSave,
  text = 'Укажите период',
  startDateDefault = null,
  endDateDefault = null,
  canReset,
}: ModalProps): JSX.Element => {
  const [value, setValue] = useState<DateRange>({
    start: startDateDefault || new Date(),
    end: endDateDefault || new Date(),
  });
  const [error, setError] = useState<ModalErrors>({});

  const handleChangeValue = (propName: string, newDate: Date): void => {
    if (!isNaN(newDate.getTime())) {
      setValue({
        ...value,
        [propName]: newDate,
      });
    }
  };

  const handleSave = (): void => {
    const { start, end } = value;
    const normalizedValue = getNormalizedDateRange(start, end);
    onClose();
    setTimeout(() => {
      onSave(normalizedValue);
    }, 100);
  };

  const handleClose = (): void => {
    onClose();
  };

  const validateValues = (from: Date, to: Date): void => {
    const newError: ModalErrors = {};

    if (from > to) {
      newError.from = 'Дата от не может быть больше Даты до';
      newError.to = 'Дата до не может быть меньше Даты от';
    }
    setError(newError);
  };

  const hasErrors = !!error.from || !!error.to;

  useEffect(() => {
    setValue({ start: startDateDefault || new Date(), end: endDateDefault || new Date() });
  }, [startDateDefault, endDateDefault]);

  useEffect(() => {
    validateValues(value.start, value.end);
  }, [value]);

  const handleReset = (): void => {
    onClose();
    setTimeout(() => {
      onSave();
    }, 100);
  };

  return (
    <DialogContainer
      key={`${isOpen}${startDateDefault}${endDateDefault}`}
      open={isOpen}
      onClose={handleClose}
      dialogTitle={text}
      maxWidth={'lg'}
      dialogContent={
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ruLocale}>
          <Grid container alignItems={'center'} p={1} spacing={1} flexWrap={'nowrap'}>
            <Grid item>
              <DatePicker format="dd.MM.yyyy" label="начало" value={value.start} onChange={date => handleChangeValue('start', date)} />
            </Grid>
            <Grid item>
              <DatePicker format="dd.MM.yyyy" label="конец" value={value.end} onChange={date => handleChangeValue('end', date)} />
            </Grid>
            {(startDateDefault || endDateDefault) && canReset && (
              <Grid item>
                <Button variant={'outlined'} size={'small'} title={'Сбросить значение фильтра'} onClick={handleReset}>
                  Сбросить
                </Button>
              </Grid>
            )}
          </Grid>
        </LocalizationProvider>
      }
      canselBtnText={'Отмена'}
      successBtnText={'Сохранить'}
      handleSuccess={handleSave}
      disabled={hasErrors}
    />
  );
};

export default Modal;
