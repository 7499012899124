import React, { FC, JSX, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Grid from '@mui/material/Grid';
import { Link as MxLink } from '@mx-ui/ui';
import { ClientTitleBreadcrumb } from '../../views/deals/DealHeader';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ThreadLink } from '../Bills/ThreadLink';
import Skeleton from '@mui/material/Skeleton';
import { Customer } from '../../api/marketx';
import Button from '@mui/material/Button';
import { DocTypes, saleDocuments } from '../../store/MenuSuccessStore';
import { useRouter } from 'next/router';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import { Popper } from '@mui/material';
import { landingCrumbsSx } from '../report/ReportCrumbs';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAuthStore } from '../../store/MobxStoreProvider';

interface SalesCrumbsProps {
  customer?: Customer;
  docName?: DocTypes;
  threadLink?: string;
  docDescription?: string;
  isLoaded?: boolean;
}

export const SalesCrumbs: FC<SalesCrumbsProps> = observer(
  ({ customer, docName, threadLink, docDescription, isLoaded = true }): JSX.Element => {
    const authStore = useAuthStore();
    const router = useRouter();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [open, setOpen] = useState(false);

    const neighbors = Object.keys(saleDocuments);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      setAnchorEl(event.currentTarget);
      setOpen(!open);
    };

    const handleClose = (): void => {
      setOpen(false);
    };

    const id = `popper-sales-crumbs`;
    return (
      <Grid item mb={1}>
        <Grid container alignItems="center">
          <Grid item>
            <Grid container alignItems={'center'}>
              <Grid item>
                <MxLink href={'/app/sales'} variant="body1" color="text.secondary">
                  Продажи
                </MxLink>
              </Grid>
              {docName && (
                <Grid item ml={2}>
                  /
                </Grid>
              )}
              {docName && (
                <Grid item ml={1}>
                  <Button
                    color={'primary'}
                    aria-describedby={id}
                    onClick={handleClick}
                    sx={{ textTransform: 'inherit', fontSize: '16px', fontWeight: '400' }}
                  >
                    <Grid container alignItems={'center'}>
                      <Grid item>{saleDocuments[docName].title}</Grid>
                      <Grid item height={'25px'} ml={1}>
                        <ExpandMoreIcon sx={{ fontSize: '15px', transition: 'all ease .3s', transform: `rotate(${open ? 180 : 0}deg)` }} />
                      </Grid>
                    </Grid>
                  </Button>
                </Grid>
              )}
              {customer && (
                <Grid item ml={1}>
                  /
                </Grid>
              )}
              {customer && (
                <Grid item ml={1}>
                  <ClientTitleBreadcrumb customer={customer} />
                </Grid>
              )}
              {isLoaded && docDescription && (
                <Grid item ml={1}>
                  /
                </Grid>
              )}
              {isLoaded && docDescription && (
                <Grid item ml={1}>
                  <Grid
                    position={'relative'}
                    sx={{
                      ['@media (max-width:500px)']: {
                        width: '97%',
                      },
                    }}
                  >
                    {docDescription && (
                      <Typography variant="body1" color="text.secondary">
                        {docDescription}
                      </Typography>
                    )}
                    {threadLink && (
                      <Box position={'absolute'} top={'-2px'} right={'-30px'}>
                        <ThreadLink link={threadLink} />
                      </Box>
                    )}
                  </Grid>
                </Grid>
              )}
              {!isLoaded && <Skeleton variant="text" width={400} />}
            </Grid>
          </Grid>
        </Grid>
        <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom" transition disablePortal={false} sx={{ zIndex: 1110 }}>
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList sx={landingCrumbsSx}>
                    {neighbors
                      .filter(i => authStore.menuSuccessStore.hasSalesAccessByName(i as DocTypes))
                      .map(n => (
                        <MenuItem
                          color="info"
                          key={n}
                          selected={n === docName}
                          onClick={() => {
                            router.push(saleDocuments[n].href);
                            handleClose();
                          }}
                        >
                          <Typography variant={'body1'} color={'text.secondary'}>
                            {saleDocuments[n].title}
                          </Typography>
                        </MenuItem>
                      ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    );
  }
);
