import { makeAutoObservable } from 'mobx';
import { ApiStore } from './Global/ApiStore';
import { AppUserGroupCodes } from '../types/AppUserGroup';
import { AuthStore } from './AuthStore';
import { ReactElement } from 'react';
import { IconByNameDocument } from '../components/SearchDocuments/DialogSearchDocumentsListByName';

const accessUserGroupCodes: string[] = [
  AppUserGroupCodes.SELLER,
  AppUserGroupCodes.SELLER_CHIEF,
  AppUserGroupCodes.OFFICE_CHIEF,
  AppUserGroupCodes.OFFICE_MARKETER,
  AppUserGroupCodes.DIVISION_CHIEF,
  AppUserGroupCodes.COMPANY_CHIEF,
  AppUserGroupCodes.COMPANY_MARKETER,
  AppUserGroupCodes.ADMIN,
  AppUserGroupCodes.MOTIVATION_RESPONSIBLE,
  AppUserGroupCodes.CUSTOMER_EDITOR,
  AppUserGroupCodes.DIVISION_MARKETER,
  AppUserGroupCodes.CALL_CENTER_OPERATOR,
  AppUserGroupCodes.SUPPORT,
];

export const accessMotivationUserGroupCodes: string[] = [
  AppUserGroupCodes.ADMIN,
  AppUserGroupCodes.MOTIVATION_RESPONSIBLE,
  AppUserGroupCodes.OFFICE_CHIEF,
  AppUserGroupCodes.DIVISION_CHIEF,
  AppUserGroupCodes.COMPANY_CHIEF,
  AppUserGroupCodes.SELLER,
  AppUserGroupCodes.SELLER_CHIEF,
];
export interface MenuSection {
  href?: string;
  name: string;
  icon?: ReactElement;
  title: string;
  access?: string[];
  accessFunc?: (auth: AuthStore) => boolean;
  categoryCode?: string;
  categoryName?: string;
  childItems?: Record<string, MenuSection>;
}
export type DocTypes = 'agreements' | 'contracts' | 'deals' | 'bills' | 'shipments' | 'returns' | 'payments' | 'debts' | 'approvals';

export type ReportCatNames =
  | 'targets'
  | 'motivation'
  | 'payment'
  | 'receivable'
  | 'indicators'
  | 'agreements'
  | 'reserves'
  | 'metabase'
  | 'datalens';
export const linksWithAccess: Record<string, MenuSection> = {
  targets: {
    title: 'Показатели План/Факт',
    name: 'reportTargets',
    href: '/app/report/targets',
    access: accessUserGroupCodes,
    categoryName: 'Мотивация',
    categoryCode: 'motivation',
  },
  motivation: {
    title: 'Премии',
    name: 'reportMotivation',
    href: '/app/report/motivation',
    access: accessMotivationUserGroupCodes,
    categoryName: 'Мотивация',
    categoryCode: 'motivation',
  },
  payment: {
    title: 'График платежей',
    name: 'reportPayment',
    href: '/app/report/payment',
    access: accessUserGroupCodes,
    categoryName: 'Контроль оплат',
    categoryCode: 'payment',
  },
  receivable: {
    title: 'Дебиторская задолженность',
    name: 'reportReceivable',
    href: '/app/report/receivable',
    access: accessUserGroupCodes,
    categoryName: 'Контроль оплат',
    categoryCode: 'payment',
  },
  indicators: {
    title: 'Воронка продаж',
    name: 'reportIndicators',
    href: '/app/report/indicators',
    access: accessUserGroupCodes,
    categoryName: 'Управление сделками',
    categoryCode: 'indicators',
  },
  reserves: {
    title: 'Резервы',
    name: 'reportReserves',
    href: '/app/report/reserves',
    access: accessUserGroupCodes,
    categoryName: 'Управление сделками',
    categoryCode: 'indicators',
  },
  agreements: {
    title: 'Соглашения',
    name: 'reportAgreements',
    href: '/app/report/agreements',
    access: accessUserGroupCodes,
    categoryName: 'Управление сделками',
    categoryCode: 'indicators',
  },
  metabase: {
    title: 'Управленческие',
    name: 'graphs',
    href: '/app/tools/metabase',
    access: [AppUserGroupCodes.ADMIN, AppUserGroupCodes.MANAGEMENT_REPORTING],
    categoryName: 'Управленческие отчеты',
    categoryCode: 'metabase',
    childItems: {
      mrc_compliance: {
        title: 'Соответствие МРЦ рынку',
        name: 'mrc_compliance',
        href: '/app/tools/metabase?tab=0',
        access: [AppUserGroupCodes.ADMIN, AppUserGroupCodes.MANAGEMENT_REPORTING],
      },
      mop_motivation: {
        title: 'Мотивация МОП',
        name: 'mop_motivation',
        href: '/app/tools/metabase?tab=1',
        access: [AppUserGroupCodes.ADMIN, AppUserGroupCodes.MANAGEMENT_REPORTING],
      },
      powers: {
        title: 'Полномочия',
        name: 'powers',
        href: '/app/tools/metabase?tab=2',
        access: [AppUserGroupCodes.ADMIN, AppUserGroupCodes.MANAGEMENT_REPORTING],
      },
      mrc_powers: {
        title: 'МРЦ и Полномочия',
        name: 'mrc_powers',
        href: '/app/tools/metabase?tab=3',
        access: [AppUserGroupCodes.ADMIN, AppUserGroupCodes.MANAGEMENT_REPORTING],
      },
      mrc_change: {
        title: 'Изменение МРЦ',
        name: 'mrc_change',
        href: '/app/tools/metabase?tab=4',
        access: [AppUserGroupCodes.ADMIN, AppUserGroupCodes.MANAGEMENT_REPORTING],
      },
    },
  },
  datalens: {
    title: 'Клиенты',
    name: 'datalens',
    href: '/app/tools/metabase',
    categoryName: 'Клиенты',
    categoryCode: 'datalens',
    childItems: {
      customer_interactions: {
        title: 'Взаимодействия',
        name: 'customer_interactions',
        href: '/app/report/datalens?reportCode=customer_interactions',
      },
      customer_sales: {
        title: 'Продажи',
        name: 'customer_sales',
        href: '/app/report/datalens?reportCode=customer_sales',
      },
      customer_states: {
        title: 'Статусы',
        name: 'customer_states',
        href: '/app/report/datalens?reportCode=customer_states',
      },
    },
  },
};

export const saleDocuments: Record<string, MenuSection> = {
  contracts: {
    title: 'Договоры',
    icon: IconByNameDocument['contracts'],
    href: '/app/contracts',
    name: 'contracts',
  },
  agreements: {
    name: 'agreements',
    title: 'Соглашения',
    icon: IconByNameDocument['agreements'],
    href: '/app/agreements',
    accessFunc: (auth: AuthStore): boolean => {
      return Boolean(auth.profile?.features?.hasAgreementsAccess);
    },
  },
  deals: {
    name: 'deals',
    title: 'Заявки',
    icon: IconByNameDocument['deals'],
    href: '/app/deals',
    access: accessUserGroupCodes,
  },
  bills: {
    name: 'bills',
    title: 'Счета',
    icon: IconByNameDocument['bills'],
    href: '/app/bills',
  },
  salesInvoices: {
    name: 'salesInvoices',
    title: 'Расходные накладные',
    icon: IconByNameDocument['salesInvoices'],
    href: '',
  },
  shipments: {
    name: 'shipments',
    title: 'Отгрузки',
    icon: IconByNameDocument['shipments'],
    href: '/app/shipments',
  },
  returns: {
    name: 'returns',
    title: 'Возвраты',
    icon: IconByNameDocument['returns'],
    href: '/app/returns',
  },
  payments: {
    name: 'payments',
    title: 'Оплаты',
    icon: IconByNameDocument['payments'],
    href: '/app/payments',
  },
  debts: {
    name: 'debts',
    title: 'Списания долга',
    icon: IconByNameDocument['debts'],
    href: '/app/debts',
  },
  approvals: {
    name: 'approvals',
    title: 'Согласования',
    icon: IconByNameDocument['approvals'],
    href: '/app/approvals',
    access: accessUserGroupCodes,
  },
};

export class MenuSuccessStore {
  apiStore: ApiStore;
  authStore: AuthStore;
  constructor(authStore: AuthStore) {
    this.apiStore = authStore.apiStore;
    this.authStore = authStore;
    makeAutoObservable(this);
  }

  hasReportAccessByName(menuName: ReportCatNames): boolean {
    if (linksWithAccess[menuName]?.accessFunc) {
      return linksWithAccess[menuName].accessFunc(this.authStore);
    } else if (linksWithAccess[menuName]?.access?.length) {
      return this.authStore.inAnyGroup(linksWithAccess[menuName].access);
    }
    return true;
  }

  hasSalesAccessByName(menuName: DocTypes): boolean {
    if (saleDocuments[menuName]?.accessFunc) {
      return saleDocuments[menuName].accessFunc(this.authStore);
    } else if (saleDocuments[menuName]?.access?.length) {
      return this.authStore.inAnyGroup(saleDocuments[menuName].access);
    }
    return true;
  }
}
