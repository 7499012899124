import React, { CSSProperties, FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { AppDealPosition } from '../../../slices/AppDeal';
import { DealItemStore } from '../../../store/DealItemStore';
import Grid from '@mui/material/Grid';
import { ProductPacksStore } from '../../../store/ProductPacksStore';
import { ContractCostPosition } from '../../../api/marketx';
import { TabSelectWrapper } from '../../../components/Deals/CustomIconTabs/TabSelectWrapper';
import { Box } from '@mui/system';
import Skeleton from '@mui/material/Skeleton';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import { DealPositionAdditionalTabHeader } from './DealPositionAdditionalTabHeader';
import { ArrivalPartyTableHeader } from 'src/components/Product/New/ArrivalPartyTableHeader';
import { ArrivalPartyTableBody } from 'src/components/Product/New/ArrivalPartyTableBody';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Table } from '@mui/material';
import { AgreementItemStore } from 'src/store/Clients/AgreementItemStore';

interface DealPositionAdditionalTabPacksProps {
  store?: DealItemStore | AgreementItemStore;
  dealPosition?: AppDealPosition;
  agreementPosition?: ContractCostPosition;
  packsStore?: ProductPacksStore;
}

const listStyle = { overflowX: 'hidden', overflowY: 'hidden', padding: 1 } as CSSProperties;
const listSkeleton = <Skeleton variant="rectangular" style={{ margin: '10px' }} />;

// Закладка с информацией о товаре в строке товара в сделке
export const DealPositionAdditionalTabPacks: FC<DealPositionAdditionalTabPacksProps> = observer(
  ({ store, dealPosition, agreementPosition, packsStore }): JSX.Element => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    useEffect(() => {
      if (store instanceof DealItemStore && dealPosition) {
        packsStore.loadForProduct(dealPosition.productCode, dealPosition?.warehouseCode || store.deal?.warehouseCode);
      }
      if (agreementPosition) {
        packsStore.loadForProduct(agreementPosition.productCode, '~total');
      }
    }, [packsStore, store, dealPosition, agreementPosition]);

    const onOpenDialog = (isOpen: boolean): void => {
      setIsDialogOpen(isOpen);
    };

    if (!packsStore.isLoaded) {
      return (
        <Box p={2}>
          <Skeleton />
        </Box>
      );
    }
    return (
      <TabSelectWrapper infiniteScrollId={'dealPositionAdditionalTabPacks'} onOpen={onOpenDialog} maxWidth={false}>
        <Grid item sx={{ width: '100%', height: '100%' }} className="withCustomScroll">
          <Grid container sx={{ display: 'grid', gridTemplateColumns: 'minmax(310px, 1fr)', MozPaddingBottom: '15px' }}>
            <DealPositionAdditionalTabHeader
              Icon={StoreOutlinedIcon}
              isDialogOpen={isDialogOpen}
              position={dealPosition || agreementPosition}
            />
            <Grid item xs={12} minWidth={'1200px'}>
              <InfiniteScroll
                dataLength={packsStore.packs?.length}
                next={() => packsStore.loadMore()}
                hasMore={packsStore.hasMore}
                style={listStyle}
                loader={listSkeleton}
                scrollableTarget={'dealPositionAdditionalTabPacks'}
              >
                <Table size="small">
                  <ArrivalPartyTableHeader store={store} />
                  <ArrivalPartyTableBody
                    packsStore={packsStore}
                    product={dealPosition?.product || agreementPosition.product}
                    store={store}
                    dealPosition={dealPosition}
                  />
                </Table>
              </InfiniteScroll>
            </Grid>
          </Grid>
        </Grid>
      </TabSelectWrapper>
    );
  }
);
