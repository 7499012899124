import React, { FC, JSX, useState } from 'react';
import { SxProps, Theme } from '@mui/material/styles';
import { observer } from 'mobx-react-lite';
import Grid from '@mui/material/Grid';
import { Link as MxLink } from '@mx-ui/ui';
import { linksWithAccess, ReportCatNames } from '../../store/MenuSuccessStore';
import { Popper } from '@mui/material';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Button from '@mui/material/Button';
import { useRouter } from 'next/router';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAuthStore } from '../../store/MobxStoreProvider';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';

export const landingCrumbsSx = { maxHeight: '400px', overflow: 'hidden', overflowY: 'auto' } as SxProps<Theme>;
interface ReportCrumbsProps {
  reportName?: ReportCatNames;
  reportChildName?: string;
}

export const ReportCrumbs: FC<ReportCrumbsProps> = observer(({ reportName, reportChildName }): JSX.Element => {
  const authStore = useAuthStore();
  const router = useRouter();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);

  const neighbors = Object.keys(linksWithAccess);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const id = `popper-report-crumbs`;

  return (
    <Grid item mb={1}>
      <Grid container alignItems="center">
        <Grid item>
          <Grid container alignItems={'center'}>
            <Grid item mr={2}>
              <MxLink href={'/app/report'} variant="body1" color="text.secondary">
                Аналитика и отчеты
              </MxLink>
            </Grid>
            {reportName && (
              <Grid item mr={1}>
                /
              </Grid>
            )}
            {reportName && (
              <Grid item>
                <Button
                  color={'primary'}
                  aria-describedby={id}
                  onClick={handleClick}
                  sx={{ textTransform: 'inherit', fontSize: '16px', fontWeight: '400' }}
                >
                  <Grid container alignItems={'center'}>
                    <Grid item>
                      {reportChildName ? linksWithAccess[reportName].childItems[reportChildName].title : linksWithAccess[reportName].title}
                    </Grid>
                    <Grid item height={'25px'} ml={1}>
                      <ExpandMoreIcon sx={{ fontSize: '15px', transition: 'all ease .3s', transform: `rotate(${open ? 180 : 0}deg)` }} />
                    </Grid>
                  </Grid>
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom" transition disablePortal={false} sx={{ zIndex: 1110 }}>
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList sx={landingCrumbsSx} className="withCustomScroll">
                  {neighbors
                    .filter(i => authStore.menuSuccessStore.hasReportAccessByName(i as ReportCatNames))
                    .map((n, index, arr) => {
                      return (
                        <Box key={n} px={1}>
                          {linksWithAccess[n].categoryName !== linksWithAccess[arr[index - 1]]?.categoryName && (
                            <Box>
                              <Typography variant={'body2'} fontWeight={500}>
                                {linksWithAccess[n].categoryName}
                              </Typography>
                            </Box>
                          )}
                          {linksWithAccess[n].childItems ? (
                            Object.keys(linksWithAccess[n].childItems).map(child => (
                              <MenuItem
                                key={child}
                                color="info"
                                selected={reportName === n && child === reportChildName}
                                onClick={() => {
                                  router.push(linksWithAccess[n].childItems[child].href, undefined, { shallow: true });
                                  handleClose();
                                }}
                              >
                                <Typography variant={'body1'} color={'text.secondary'}>
                                  {linksWithAccess[n].childItems[child].title}
                                </Typography>
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem
                              color="info"
                              selected={reportName === n}
                              onClick={() => {
                                router.push(linksWithAccess[n].href);
                                handleClose();
                              }}
                            >
                              <Typography variant={'body1'} color={'text.secondary'}>
                                {linksWithAccess[n].title}
                              </Typography>
                            </MenuItem>
                          )}
                        </Box>
                      );
                    })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Grid>
  );
});
