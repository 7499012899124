import React, { Dispatch, FC, SetStateAction } from 'react';
import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined';
import CardTravelOutlinedIcon from '@mui/icons-material/CardTravelOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import CleaningServicesOutlinedIcon from '@mui/icons-material/CleaningServicesOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import Box from '@mui/material/Box';
import { observer } from 'mobx-react-lite';
import { HandShakeIcon } from 'src/components/ui/icons/HandShakeIcon';
import CreditScoreOutlinedIcon from '@mui/icons-material/CreditScoreOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import { DocumentsSearchStore } from 'src/store/Documents/DocumentsSearchStore';
import Rotate90DegreesCcwIcon from '@mui/icons-material/Rotate90DegreesCcw';
import { Link as MxLink } from '@mx-ui/ui';
import NextWeekOutlinedIcon from '@mui/icons-material/NextWeekOutlined';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { saleDocuments } from '../../store/MenuSuccessStore';
interface DialogSearchDocumentsListByNameProps {
  store: DocumentsSearchStore;
  document: Record<string, string>;
  name: string;
  setOpenSearchModal: Dispatch<SetStateAction<boolean>>;
}
export const IconByNameDocument = {
  agreements: <HandShakeIcon />,
  approvals: <CreditScoreOutlinedIcon />,
  bills: <CreditCardOutlinedIcon />,
  contracts: <AssignmentTurnedInOutlinedIcon />,
  deals: <AddCardOutlinedIcon />,
  debts: <CleaningServicesOutlinedIcon />,
  payments: <PaymentsOutlinedIcon />,
  returns: <Rotate90DegreesCcwIcon />, // не состыковка с иконками в менюшке, но в листе все норм
  salesInvoices: <NextWeekOutlinedIcon />,
  shipments: <CardTravelOutlinedIcon />,
};
export const DialogSearchDocumentsListByName: FC<DialogSearchDocumentsListByNameProps> = observer(
  ({ store, document, name, setOpenSearchModal }) => {
    const link = store.linkByDocument(document, name);
    const title = store.titleByDocument(document, name);
    const customer = store.customerTitleAndLink(document);
    const saleItem = saleDocuments[name];
    const handlePath = (): void => {
      setOpenSearchModal(false);
    };
    return (
      <Grid container mb={0.8} mt={1} key={title + link}>
        <Box component="span" ml={0.5} color={theme => (theme.palette.mode === 'dark' ? 'text.secondary' : 'text.primary')}>
          {saleItem.icon}
        </Box>
        {link ? (
          <Grid item ml={1}>
            <MxLink fontWeight={500} color={'primary'} href={link} title={title} onClick={handlePath}>
              {title}
            </MxLink>
          </Grid>
        ) : (
          <Typography>{title}</Typography>
        )}
        {customer.link && customer.title && (
          <Grid item ml={1}>
            <MxLink href={customer.link} color="text.secondary" typographySx={{ fontSize: 14, fontWeight: 600 }} onClick={handlePath}>
              {customer.title}
            </MxLink>
          </Grid>
        )}
      </Grid>
    );
  }
);
