import { RootStore } from './StoreManager';
import { makeAutoObservable, observable, runInAction, set, toJS } from 'mobx';
import { ApiStore } from './Global/ApiStore';
import { AuthStore } from './AuthStore';
import { RouterStore } from './RouterStore';
import { AppUserGroupCodes } from '../types/AppUserGroup';
import { SnackbarStore } from './SnackbarStore';
import { getPropertyByEntity } from './EmployeesMarginalityStore';
import { AxiosResponse } from 'axios';
import {
  ClientContract,
  ClientsForFilterResponse,
  CreditState,
  Customer,
  CustomerAsFilter,
  CustomerNotDistributedStatItem,
  PaymentExpectation,
  StatPaymentsReceivableReportResponse,
  StatPaymentsReport,
  StatReferencesResponse,
} from '../api/marketx';
import { ParsedUrlQuery } from 'querystring';
import { formatDateSwaggerZ } from '@mx-ui/helpers';

export type Code = string;
export interface ReceivableSelectedCrumb {
  code?: Code; //* должно быть обязательно
  title?: string;
}
const ReceivableTabNamesTypes = <const>['clients'];
export type ReceivableTabNames = (typeof ReceivableTabNamesTypes)[number];

const ReceivableRoleEmployeeTypes = <const>['company', 'division', 'office', 'department', 'employee'];
export type ReceivableRoleEmployee = (typeof ReceivableRoleEmployeeTypes)[number];

const defaultCustomerFilterCount = 100;

export interface AllowedReceivableParams {
  tab?: ReceivableTabNames;
  selectedCrumbCode?: string;
  mode?: ReceivableRoleEmployee;
  overdueDaysFrom?: number | null;
  overdueDaysTo?: number | null;
  daysLeftFrom?: number | null;
  daysLeftTo?: number | null;
  query?: string | null;
  awaitingAmountFrom?: number | null;
  awaitingAmountTo?: number | null;
  overdueAmountFrom?: number | null;
  overdueAmountTo?: number | null;
  customerCodes?: string[];
  plannedDateFrom?: string;
  plannedDateTo?: string;
  dateFrom?: string;
  dateTo?: string;
  /*   queryCustomer?: string; */

  customerFilterPage?: number;
  customerFilterCount?: number;
}

export const RECEIVABLE_ROLE_EMPLOYEE_CODES = {
  employee: 'employee' as ReceivableRoleEmployee,
  department: 'department' as ReceivableRoleEmployee,
  division: 'division' as ReceivableRoleEmployee,
  company: 'company' as ReceivableRoleEmployee,
  office: 'office' as ReceivableRoleEmployee,
};

export const RECEIVABLE_TAB_NAME_CODES = {
  clients: 'clients' as ReceivableTabNames,
};

const allowTabsByRole: Record<ReceivableRoleEmployee, ReceivableTabNames[]> = {
  employee: [RECEIVABLE_TAB_NAME_CODES.clients],
  department: [RECEIVABLE_TAB_NAME_CODES.clients],
  office: [RECEIVABLE_TAB_NAME_CODES.clients],
  division: [RECEIVABLE_TAB_NAME_CODES.clients],
  company: [RECEIVABLE_TAB_NAME_CODES.clients],
};

export const namesCrumbsReceivableNoValue: Record<ReceivableRoleEmployee, string> = {
  employee: 'Все менеджеры',
  department: 'Все отделы',
  office: 'Все филиалы',
  division: 'Все дивизионы',
  company: '',
};

export const mapReceivableFromTo = (
  initObj: { from: number; to: number },
  val: number | null | undefined
): { from: number; to: number } => {
  if (!val) {
    return initObj;
  }
  return {
    from: initObj?.from === 0 ? val : initObj?.from > val ? val : initObj?.from,
    to: initObj?.to === 0 ? val : initObj?.to < val ? val : initObj?.to,
  };
};

export const mapReceivablePlannedDate = (currentPlannedDate: string, plannedDate: string | null | undefined): string => {
  if (!plannedDate) {
    return currentPlannedDate;
  }
  if (!currentPlannedDate) {
    return plannedDate;
  }
  return new Date(currentPlannedDate) < new Date(plannedDate) ? currentPlannedDate : plannedDate;
};

export type CustomersByCode = {
  customer: Customer;
  overdueAmount: number;
  awaitingAmount: number;
  notDistributed: number;
  totalLoanAmount: number;
  customerDaysLeft: { from: number; to: number };
  customerDaysOverdue: { from: number; to: number };
  customerPlannedDate: string | null;
  plansByContractCode: Record<
    string,
    {
      byBillCode: Record<
        string,
        {
          overdueAmount: number;
          awaitingAmount: number;
          billPlannedDate: string | null;
          billDaysLeft: { from: number; to: number };
          billDaysOverdue: { from: number; to: number };
          shipments: Array<PaymentExpectation>;
        }
      >;
      contractPlannedDate: string | null;
      contractDaysLeft: { from: number; to: number };
      contractLoanAmount: number;
      maxInterestRatePct: number;
      shipmentCodeOnly: Array<PaymentExpectation>;
      overdueAmount: number;
      awaitingAmount: number;
      daysOverduePeriod: Set<number>;
    }
  >;
  otherPlans: {
    plans: Array<PaymentExpectation>;
    overdueAmount: number;
    awaitingAmount: number;
    daysOverduePeriod: Set<number>;
  };
  // otherPlans: Array<PaymentExpectation>;
};

export type OverdueDays = {
  from: number | null;
  to: number | null;
};

export function transformQueryToAllowedParams<T>(query: ParsedUrlQuery): T {
  const obj = {} as any;
  const today = new Date();
  if (query.selectedCrumbCode) {
    obj.selectedCrumbCode = query.selectedCrumbCode as string;
  }
  if (query.dateFrom) {
    obj.dateFrom = query.dateFrom as string;
  } else {
    obj.dateFrom = formatDateSwaggerZ(new Date(today.getFullYear() - 1, 1, -29));
  }
  if (query.dateTo) {
    obj.dateTo = query.dateTo as string;
  } else {
    obj.dateTo = formatDateSwaggerZ(new Date(today.getFullYear(), 11, 32));
  }
  if (query.plannedDateFrom) {
    obj.plannedDateFrom = query.plannedDateFrom as string;
  }
  if (query.plannedDateTo) {
    obj.plannedDateTo = query.plannedDateTo as string;
  }
  if (query.overdueDaysFrom && !isNaN(Number(query.overdueDaysFrom))) {
    obj.overdueDaysFrom = Number(query.overdueDaysFrom);
  }
  if (query.overdueDaysTo && !isNaN(Number(query.overdueDaysTo))) {
    obj.overdueDaysTo = Number(query.overdueDaysTo);
  }
  if (query.daysLeftFrom && !isNaN(Number(query.daysLeftFrom))) {
    obj.daysLeftFrom = Number(query.daysLeftFrom);
  }
  if (query.daysLeftTo && !isNaN(Number(query.daysLeftTo))) {
    obj.daysLeftTo = Number(query.daysLeftTo);
  }
  if (query.query) {
    obj.query = query.query;
  }
  if (query.awaitingAmountFrom && !isNaN(Number(query.awaitingAmountFrom))) {
    obj.awaitingAmountFrom = Number(query.awaitingAmountFrom);
  }
  if (query.awaitingAmountTo && !isNaN(Number(query.awaitingAmountTo))) {
    obj.awaitingAmountTo = Number(query.awaitingAmountTo);
  }
  if (query.overdueAmountFrom && !isNaN(Number(query.overdueAmountFrom))) {
    obj.overdueAmountFrom = Number(query.overdueAmountFrom);
  }
  if (query.overdueAmountTo && !isNaN(Number(query.overdueAmountTo))) {
    obj.overdueAmountTo = Number(query.overdueAmountTo);
  }
  if (query.customerCodes && typeof query.customerCodes === 'string') {
    obj.customerCodes = query.customerCodes.split(',');
  }
  return obj;
}

export class ReceivableStatStore {
  apiStore: ApiStore;
  authStore: AuthStore;
  routerStore: RouterStore;
  snackbarStore: SnackbarStore;
  request: AllowedReceivableParams = {
    query: '',
  };
  role: ReceivableRoleEmployee;
  mode: ReceivableRoleEmployee;
  documentsCustomersFilterList: CustomerAsFilter[] = [];
  totalReceivableData: StatPaymentsReport = null;

  isFilterCustomerLoading = true;
  isLoading = true;
  isLoadingMore = false;
  isTotalReceivableDataLoading = false;
  canLoadMore = true;

  selectedTab: ReceivableTabNames = RECEIVABLE_TAB_NAME_CODES.clients;
  allowTabs: ReceivableTabNames[] = [];
  isLoadedTabs = {
    clients: false,
  };
  crumbsValues: Record<ReceivableRoleEmployee, ReceivableSelectedCrumb[]> = {
    company: [],
    division: [],
    office: [],
    department: [],
    employee: [],
  };
  selectedCrumbs: Record<ReceivableRoleEmployee, ReceivableSelectedCrumb> = {
    company: undefined,
    division: undefined,
    office: undefined,
    department: undefined,
    employee: undefined,
  };
  enabledCrumbs: ReceivableRoleEmployee[] = [];
  /**
   * Управляем адресом страницы
   */
  routerControlEnabled = false;

  accountsReceivable = 0;
  overdueReceivables = 0;

  customersByCode: Record<string, CustomersByCode> = null;
  subjectsTotalReportsDataByCode: Record<string, { title: string; accountsReceivable: number; overdueReceivables: number }> = null;
  subjectsTotalReportsDataModeCode = '';
  contractsByCode: Record<string, ClientContract>;
  creditStatesByCodes: Record<string, CreditState>;
  notDistributedStatesByCodes: Record<string, CustomerNotDistributedStatItem>;

  receivablePage = 1;
  countPerPage = 20;

  constructor(rootStore: RootStore) {
    this.apiStore = rootStore.getApiStore();
    this.authStore = rootStore.getAuth();
    this.snackbarStore = rootStore.getSnackbar();
    this.role = this.getTypeByRole();
    this.routerStore = rootStore.getRouter();
    this.request = observable({
      query: '',
    });
    this.setMode(this.role);
    makeAutoObservable(this, { apiStore: false, snackbarStore: false, getTypeByRole: false }, { autoBind: true });
  }

  getTypeByRole(): ReceivableRoleEmployee {
    const isDivisionChief = this.authStore.inGroup(AppUserGroupCodes.DIVISION_CHIEF);
    const isDivisionMarketer = this.authStore.inGroup(AppUserGroupCodes.DIVISION_MARKETER);
    const isCallCenterOperator = this.authStore.inGroup(AppUserGroupCodes.CALL_CENTER_OPERATOR);
    const isOfficeChief = this.authStore.inGroup(AppUserGroupCodes.OFFICE_CHIEF);
    const isOfficeMarketer = this.authStore.inGroup(AppUserGroupCodes.OFFICE_MARKETER);
    const isSellerChief = this.authStore.inGroup(AppUserGroupCodes.SELLER_CHIEF);
    const isCompanyChief = this.authStore.inGroup(AppUserGroupCodes.COMPANY_CHIEF);
    const isCompanyMarketer = this.authStore.inGroup(AppUserGroupCodes.COMPANY_MARKETER);
    const isSupport = this.authStore.inGroup(AppUserGroupCodes.SUPPORT);

    if (isCompanyChief || isCompanyMarketer) {
      return RECEIVABLE_ROLE_EMPLOYEE_CODES.company;
    }
    if (isDivisionChief || isDivisionMarketer) {
      return RECEIVABLE_ROLE_EMPLOYEE_CODES.division;
    }
    if (isOfficeChief || isSupport || isOfficeMarketer || isCallCenterOperator) {
      return RECEIVABLE_ROLE_EMPLOYEE_CODES.office;
    }
    if (isSellerChief) {
      return RECEIVABLE_ROLE_EMPLOYEE_CODES.department;
    }
    return RECEIVABLE_ROLE_EMPLOYEE_CODES.employee;
  }

  mergeRequest(req?: AllowedReceivableParams): void {
    this.setRequestFromObj(req);
    this.actualizeRouter(this.request);
    this.loadReceivableStat(this.mode);
  }

  loadCustomersForFilter(): void {
    if (this.routerControlEnabled) {
      this.loadFilterCustomerCodes();
    }
  }

  setFilterCustomerResult(statuses: ClientsForFilterResponse): void {
    if (statuses.customers?.length) {
      const prevCheckedCustomers = [];
      this.documentsCustomersFilterList.forEach(prevI => {
        if (this.request.customerCodes.includes(prevI.code) && !statuses.customers.find(i => i.code === prevI.code)) {
          prevCheckedCustomers.push(prevI);
        }
      });
      if (this.request?.customerCodes?.length) {
        this.documentsCustomersFilterList = [...prevCheckedCustomers, ...statuses.customers];
      } else {
        this.documentsCustomersFilterList = [...statuses.customers];
      }
    }
    runInAction(() => {
      this.isFilterCustomerLoading = false;
    });
  }

  loadFilterCustomerCodes(): void {
    runInAction(() => {
      this.isFilterCustomerLoading = true;
    });
    const req = Object.assign({}, this.request);
    req.customerFilterPage = req.customerFilterPage > 1 ? req.customerFilterPage : undefined;
    req.customerFilterCount = req.customerFilterCount || defaultCustomerFilterCount;
    this.apiStore
      .apiClientCustomer()
      .clientsForFilter({
        documentType: 'bill',
        requiredCustomerCodes: req.customerCodes?.length ? req.customerCodes : undefined,
        dateFrom: undefined,
        dateTo: undefined,
        employeeSet: undefined,
        hasShipments: undefined,
        paymentStateCodes: undefined,
        billShipmentStateCodes: undefined,
        billSourceCodes: undefined,
        billStateCodes: undefined,
        queryDocuments: req.query || undefined,
        page: req.customerFilterPage || undefined,
        count: req.customerFilterCount,
        queryCustomers: undefined,
      })
      .then(res => {
        this.setFilterCustomerResult(res.data);
      })
      .catch(e => {
        console.warn('loadFilterCustomerCodes req', e);
        runInAction(() => {
          this.isFilterCustomerLoading = false;
        });
      });
  }

  setRouterControl(enabled: boolean): void {
    this.routerControlEnabled = enabled;
  }
  async initialLoadStatsByQuery(params: AllowedReceivableParams): Promise<void> {
    if ((this.role === 'division' || this.role === 'company') && !params.mode) {
      params.mode = 'office';
      params.selectedCrumbCode = this.authStore.profile.chosenBranchOfficeCode;
    }
    const role = this.role;

    runInAction(async () => {
      this.setRequestFromObj(params);
      if (params.mode) {
        this.setMode(params.mode);
        this.allowTabs = allowTabsByRole[this.mode];
        await this.loadStatReferences({ [this.mode]: params.selectedCrumbCode }, true);
      } else {
        this.allowTabs = allowTabsByRole[role];
        await this.loadStatReferences({}, true);
      }

      await this.loadReceivableStat(this.mode);

      // если будет несколько табов
      // if (params?.tab) {
      //   this.setSelectedTab(params.tab);
      //   this.loadStatByTab(params.tab);
      // }
    });
  }
  lazyLoadReceivable(): void {
    if (!this.canLoadMore || this.isLoading) {
      return;
    }
    const role = this.mode;
    this.receivablePage++;
    this.isLoadingMore = true;
    this.loadReceivableStat(role);
  }

  async loadReceivableStat(role: ReceivableRoleEmployee): Promise<void> {
    const tabName = RECEIVABLE_TAB_NAME_CODES.clients;

    /* this.loadCustomersForFilter(); */

    switch (role) {
      case RECEIVABLE_ROLE_EMPLOYEE_CODES.employee:
        this.loadReceivableStatEmployee(tabName, this.selectedCrumbs[role]?.code);
        break;
      case RECEIVABLE_ROLE_EMPLOYEE_CODES.department:
        this.loadReceivableStatDepartment(tabName, this.selectedCrumbs[role]?.code);
        break;
      case RECEIVABLE_ROLE_EMPLOYEE_CODES.division:
        this.loadReceivableStatDivisions(tabName, this.selectedCrumbs[role]?.code);
        break;
      case RECEIVABLE_ROLE_EMPLOYEE_CODES.office:
        this.loadReceivableStatOffices(tabName, this.selectedCrumbs[role]?.code);
        break;
      case RECEIVABLE_ROLE_EMPLOYEE_CODES.company:
        this.loadReceivableStatCompany(tabName, this.selectedCrumbs[role]?.code);
        break;

      default:
        console.warn('role received does not exist', role);
        break;
    }
  }
  async loadReceivableStatEmployee(tab: ReceivableTabNames, employeeCode: Code): Promise<void> {
    runInAction(() => {
      this.isLoading = true;
    });
    try {
      const totalRes = await this.apiStore
        .apiStatApi()
        .statPaymentsEmployees(
          employeeCode,
          this.request.awaitingAmountFrom || undefined,
          this.request.awaitingAmountTo || undefined,
          this.request.overdueAmountFrom || undefined,
          this.request.overdueAmountTo || undefined,
          this.request.query || undefined,
          undefined,
          this.request.overdueDaysFrom || undefined,
          this.request.overdueDaysTo || undefined,
          this.request.daysLeftFrom || undefined,
          this.request.daysLeftTo || undefined,
          this.request.dateFrom || undefined || undefined,
          this.request.dateTo || undefined,
          this.request.plannedDateFrom || undefined,
          this.request.plannedDateTo || undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          'overdue'
        );
      this.setTotalReceivableData(totalRes.data?.stats ? totalRes.data?.stats[0].report : null);
      const res = await this.apiStore.apiStatApi().statPaymentsReceivableReport({
        dateFrom: this.request.dateFrom || undefined,
        dateTo: this.request.dateTo || undefined,
        plannedDateFrom: this.request.plannedDateFrom || undefined,
        plannedDateTo: this.request.plannedDateTo || undefined,
        page: this.receivablePage,
        count: this.countPerPage,
        employeeCodes: [employeeCode],
        query: this.request.query || undefined,
        awaitingAmountFrom: this.request.awaitingAmountFrom || undefined,
        awaitingAmountTo: this.request.awaitingAmountTo || undefined,
        overdueAmountFrom: this.request.overdueAmountFrom || undefined,
        overdueAmountTo: this.request.overdueAmountTo || undefined,
        overdueDayFrom: this.request.overdueDaysFrom || undefined,
        overdueDayTo: this.request.overdueDaysTo || undefined,
        daysLeftFrom: this.request.daysLeftFrom || undefined,
        daysLeftTo: this.request.daysLeftTo || undefined,
        /* customerCodes: this.request.customerCodes.length ? this.request.customerCodes : undefined, */
      });
      this.setResultReceivableEmployees(res, tab);
    } catch (err) {
      console.warn('loadReceivableStatEmployee error', err);
    }
  }
  async loadReceivableStatDepartment(tab: ReceivableTabNames, departmentCode: Code): Promise<void> {
    runInAction(() => {
      this.isLoading = true;
    });
    await this.apiStore
      .apiStatApi()
      .statPaymentsDepartments(
        this.request.dateFrom || undefined,
        this.request.dateTo || undefined,
        this.request.plannedDateFrom || undefined,
        this.request.plannedDateTo || undefined,
        undefined,
        undefined,
        undefined,
        departmentCode,
        undefined,
        undefined,
        this.request.awaitingAmountFrom || undefined,
        this.request.awaitingAmountTo || undefined,
        this.request.overdueAmountFrom || undefined,
        this.request.overdueAmountTo || undefined,
        this.request.query || undefined,
        undefined,
        this.request.overdueDaysFrom || undefined,
        this.request.overdueDaysTo || undefined,
        this.request.daysLeftFrom || undefined,
        this.request.daysLeftTo || undefined,
        'overdue'
      )
      .then(totalRes => {
        this.setTotalReceivableData(totalRes.data?.stats ? totalRes.data?.stats[0].report : null);
      })
      .then(() => {
        return this.apiStore.apiStatApi().statPaymentsReceivableReport({
          dateFrom: this.request.dateFrom || undefined,
          dateTo: this.request.dateTo || undefined,
          plannedDateFrom: this.request.plannedDateFrom || undefined,
          plannedDateTo: this.request.plannedDateTo || undefined,
          page: this.receivablePage,
          count: this.countPerPage,
          departmentCodes: [departmentCode],
          query: this.request.query || undefined,
          awaitingAmountFrom: this.request.awaitingAmountFrom || undefined,
          awaitingAmountTo: this.request.awaitingAmountTo || undefined,
          overdueAmountFrom: this.request.overdueAmountFrom || undefined,
          overdueAmountTo: this.request.overdueAmountTo || undefined,
          overdueDayFrom: this.request.overdueDaysFrom || undefined,
          overdueDayTo: this.request.overdueDaysTo || undefined,
          daysLeftFrom: this.request.daysLeftFrom || undefined,
          daysLeftTo: this.request.daysLeftTo || undefined,
          /* customerCodes: this.request.customerCodes.length ? this.request.customerCodes : undefined, */
        });
      })
      .then(res => {
        this.setResultReceivableEmployees(res, tab);
      })
      .catch(err => {
        console.warn('loadReceivableStatDepartment error', err);
      });
  }
  async loadReceivableStatDivisions(tab: ReceivableTabNames, divisionCode: Code): Promise<void> {
    runInAction(() => {
      this.isLoading = true;
    });
    try {
      const totalRes = await this.apiStore
        .apiStatApi()
        .statPaymentsDivisions(
          this.request.dateFrom || undefined,
          this.request.dateTo || undefined,
          this.request.plannedDateFrom || undefined,
          this.request.plannedDateTo || undefined,
          undefined,
          undefined,
          divisionCode,
          this.request.awaitingAmountFrom || undefined,
          this.request.awaitingAmountTo || undefined,
          this.request.overdueAmountFrom || undefined,
          this.request.awaitingAmountTo || undefined,
          this.request.query || undefined,
          undefined,
          this.request.overdueDaysFrom || undefined,
          this.request.overdueDaysTo || undefined,
          this.request.daysLeftFrom || undefined,
          this.request.daysLeftTo || undefined,
          'overdue'
        );
      this.setTotalReceivableData(totalRes.data?.stats ? totalRes.data?.stats[0].report : null);

      const res = await this.apiStore.apiStatApi().statPaymentsReceivableReport({
        dateFrom: this.request.dateFrom || undefined,
        dateTo: this.request.dateTo || undefined,
        plannedDateFrom: this.request.plannedDateFrom || undefined,
        plannedDateTo: this.request.plannedDateTo || undefined,
        page: this.receivablePage,
        count: this.countPerPage,
        divisionCodes: [divisionCode],
        query: this.request.query || undefined,
        awaitingAmountFrom: this.request.awaitingAmountFrom || undefined,
        awaitingAmountTo: this.request.awaitingAmountTo || undefined,
        overdueAmountFrom: this.request.overdueAmountFrom || undefined,
        overdueAmountTo: this.request.overdueAmountTo || undefined,
        overdueDayFrom: this.request.overdueDaysFrom || undefined,
        overdueDayTo: this.request.overdueDaysTo || undefined,
        daysLeftFrom: this.request.daysLeftFrom || undefined,
        daysLeftTo: this.request.daysLeftTo || undefined,
        /* customerCodes: this.request.customerCodes.length ? this.request.customerCodes : undefined, */
      });
      this.setResultReceivableEmployees(res, tab);
    } catch (err) {
      console.warn('loadReceivableStatDivisions error', err);
    }
  }
  async loadReceivableStatOffices(tab: ReceivableTabNames, officeCode: Code): Promise<void> {
    runInAction(() => {
      this.isLoading = true;
    });
    try {
      const totalRes = await this.apiStore
        .apiStatApi()
        .statPaymentsOffices(
          this.request.dateFrom || undefined,
          this.request.dateTo || undefined,
          this.request.plannedDateFrom || undefined,
          this.request.plannedDateTo || undefined,
          undefined,
          undefined,
          undefined,
          officeCode,
          this.request.awaitingAmountFrom || undefined,
          this.request.awaitingAmountTo || undefined,
          this.request.overdueAmountFrom || undefined,
          this.request.overdueAmountTo || undefined,
          this.request.query || undefined,
          undefined,
          this.request.overdueDaysFrom || undefined,
          this.request.overdueDaysTo || undefined,
          this.request.daysLeftFrom || undefined,
          this.request.daysLeftTo || undefined,
          'overdue'
        );
      this.setTotalReceivableData(totalRes.data?.stats ? totalRes.data?.stats[0].report : null);

      const res = await this.apiStore.apiStatApi().statPaymentsReceivableReport({
        dateFrom: this.request.dateFrom || undefined,
        dateTo: this.request.dateTo || undefined,
        plannedDateFrom: this.request.plannedDateFrom || undefined,
        plannedDateTo: this.request.plannedDateTo || undefined,
        page: this.receivablePage,
        overdueDayFrom: this.request.overdueDaysFrom || undefined,
        overdueDayTo: this.request.overdueDaysTo || undefined,
        count: this.countPerPage,
        branchOfficeCodes: [officeCode],
        query: this.request.query || undefined,
        awaitingAmountFrom: this.request.awaitingAmountFrom || undefined,
        awaitingAmountTo: this.request.awaitingAmountTo || undefined,
        overdueAmountFrom: this.request.overdueAmountFrom || undefined,
        overdueAmountTo: this.request.overdueAmountTo || undefined,
        daysLeftFrom: this.request.daysLeftFrom || undefined,
        daysLeftTo: this.request.daysLeftTo || undefined,
        /* customerCodes: this.request.customerCodes.length ? this.request.customerCodes : undefined, */
      });
      this.setResultReceivableEmployees(res, tab);
    } catch (err) {
      console.warn('loadReceivableStatOffices error', err);
    }
  }
  async loadReceivableStatCompany(tab: ReceivableTabNames, headCompanyCode: Code): Promise<void> {
    runInAction(() => {
      this.isLoading = true;
    });
    try {
      const totalRes = await this.apiStore
        .apiStatApi()
        .statPaymentsHeadCompanies(
          this.request.dateFrom || undefined,
          this.request.dateTo || undefined,
          this.request.plannedDateFrom || undefined,
          this.request.plannedDateTo || undefined,
          undefined,
          undefined,
          this.request.awaitingAmountFrom || undefined,
          this.request.awaitingAmountTo || undefined,
          this.request.overdueAmountFrom || undefined,
          this.request.overdueAmountTo || undefined,
          this.request.query,
          undefined,
          this.request.overdueDaysFrom || undefined,
          this.request.overdueDaysTo || undefined,
          this.request.daysLeftFrom || undefined,
          this.request.daysLeftTo || undefined,
          'overdue'
        );
      this.setTotalReceivableData(totalRes.data?.stats ? totalRes.data?.stats[0].report : null);

      const res = await this.apiStore.apiStatApi().statPaymentsReceivableReport({
        dateFrom: this.request.dateFrom || undefined,
        dateTo: this.request.dateTo || undefined,
        plannedDateFrom: this.request.plannedDateFrom || undefined,
        plannedDateTo: this.request.plannedDateTo || undefined,
        page: this.receivablePage,
        count: this.countPerPage,
        headCompanyCode: headCompanyCode,
        query: this.request.query || undefined,
        awaitingAmountFrom: this.request.awaitingAmountFrom || undefined,
        awaitingAmountTo: this.request.awaitingAmountTo || undefined,
        overdueAmountFrom: this.request.overdueAmountFrom || undefined,
        overdueAmountTo: this.request.overdueAmountTo || undefined,
        overdueDayFrom: this.request.overdueDaysFrom || undefined,
        overdueDayTo: this.request.overdueDaysTo || undefined,
        daysLeftFrom: this.request.daysLeftFrom || undefined,
        daysLeftTo: this.request.daysLeftTo || undefined,
        /* customerCodes: this.request.customerCodes.length ? this.request.customerCodes : undefined, */
      });
      this.setResultReceivableEmployees(res, tab);
    } catch (err) {
      console.warn('loadReceivableStatCompany error', err);
    }
  }
  // eslint-disable-next-line complexity
  async loadTotalReceivableData(mode: ReceivableRoleEmployee): Promise<void> {
    if (
      this.subjectsTotalReportsDataModeCode === this.selectedCrumbs[mode]?.code ||
      this.isTotalReceivableDataLoading ||
      !this.selectedCrumbs[mode]
    ) {
      return;
    }
    this.isTotalReceivableDataLoading = true;
    let totalRes;
    try {
      if (mode === 'company') {
        totalRes = await this.apiStore
          .apiStatApi()
          .statPaymentsDivisions(
            this.request.dateFrom || undefined,
            this.request.dateTo || undefined,
            this.request.plannedDateFrom || undefined,
            this.request.plannedDateTo || undefined,
            undefined,
            undefined,
            undefined,
            this.request.awaitingAmountFrom || undefined,
            this.request.awaitingAmountTo || undefined,
            this.request.overdueAmountFrom || undefined,
            this.request.overdueAmountTo || undefined,
            this.request.query || undefined,
            undefined,
            this.request.overdueDaysFrom || undefined,
            this.request.overdueDaysTo || undefined,
            this.request.daysLeftFrom || undefined,
            this.request.daysLeftTo || undefined,
            'overdue'
          );
      } else if (mode === 'division') {
        totalRes = await this.apiStore
          .apiStatApi()
          .statPaymentsOffices(
            this.request.dateFrom || undefined,
            this.request.dateTo || undefined,
            this.request.plannedDateFrom || undefined,
            this.request.plannedDateTo || undefined,
            undefined,
            undefined,
            this.selectedCrumbs.division.code,
            undefined,
            this.request.awaitingAmountFrom || undefined,
            this.request.awaitingAmountTo || undefined,
            this.request.overdueAmountFrom || undefined,
            this.request.overdueAmountTo || undefined,
            this.request.query || undefined,
            undefined,
            this.request.overdueDaysFrom || undefined,
            this.request.overdueDaysTo || undefined,
            this.request.daysLeftFrom || undefined,
            this.request.daysLeftTo || undefined,
            'overdue'
          );
      } else if (mode === 'office') {
        totalRes = await this.apiStore
          .apiStatApi()
          .statPaymentsDepartments(
            this.request.dateFrom || undefined,
            this.request.dateTo || undefined,
            this.request.plannedDateFrom || undefined,
            this.request.plannedDateTo || undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            this.selectedCrumbs.office.code,
            undefined,
            this.request.awaitingAmountFrom || undefined,
            this.request.awaitingAmountTo || undefined,
            this.request.overdueAmountFrom || undefined,
            this.request.overdueAmountTo || undefined,
            this.request.query || undefined,
            undefined,
            this.request.overdueDaysFrom || undefined,
            this.request.overdueDaysTo || undefined,
            this.request.daysLeftFrom || undefined,
            this.request.daysLeftTo || undefined,
            'overdue'
          );
      } else if (mode === 'department') {
        totalRes = await this.apiStore
          .apiStatApi()
          .statPaymentsEmployees(
            undefined,
            this.request.awaitingAmountFrom || undefined,
            this.request.awaitingAmountTo || undefined,
            this.request.overdueAmountFrom || undefined,
            this.request.overdueAmountTo || undefined,
            this.request.query || undefined,
            undefined,
            this.request.overdueDaysFrom || undefined,
            this.request.overdueDaysTo || undefined,
            this.request.daysLeftFrom || undefined,
            this.request.daysLeftTo || undefined,
            this.request.dateFrom || undefined,
            this.request.dateTo || undefined,
            this.request.plannedDateFrom || undefined,
            this.request.plannedDateTo || undefined,
            undefined,
            undefined,
            undefined,
            this.selectedCrumbs.department.code || undefined,
            undefined,
            'overdue'
          );
      }
    } catch (e) {
      console.warn('loadTotalReceivableData error', e);
    }
    // загрузка подчиненных документов
    if (totalRes?.data) {
      const reportList = {};
      const totalResKeys = Object.keys(totalRes.data);
      totalRes?.data.stats?.forEach(item => {
        const totalResStatsKeys = Object.keys(item);
        reportList[item[totalResStatsKeys[0]]] = { ...item.report };
      });
      this.setSubjectsTotalReportsData(totalRes.data[totalResKeys[0]], reportList, mode);
    } else {
      this.isTotalReceivableDataLoading = false;
    }
  }

  loadXlsxReport(): Promise<void> {
    return this.apiStore
      .apiStatApi()
      .statPaymentsReceivableReportXlsx(
        {
          dateFrom: this.request.dateFrom || undefined,
          dateTo: this.request.dateTo || undefined,
          plannedDateFrom: this.request.plannedDateFrom || undefined,
          plannedDateTo: this.request.plannedDateTo || undefined,
          employeeCodes: this.mode === 'employee' ? [this.selectedCrumbs[this.mode].code] : undefined,
          departmentCodes: this.mode === 'department' ? [this.selectedCrumbs[this.mode].code] : undefined,
          branchOfficeCodes: this.mode === 'office' ? [this.selectedCrumbs[this.mode].code] : undefined,
          divisionCodes: this.mode === 'division' ? [this.selectedCrumbs[this.mode].code] : undefined,
          headCompanyCode: this.mode === 'company' ? this.selectedCrumbs[this.mode].code : undefined,
          overdueDayFrom: this.request.overdueDaysFrom || undefined,
          overdueDayTo: this.request.overdueDaysTo || undefined,
          daysLeftFrom: this.request.daysLeftFrom || undefined,
          daysLeftTo: this.request.daysLeftTo || undefined,
          query: this.request.query || undefined,
          awaitingAmountFrom: this.request.awaitingAmountFrom || undefined,
          awaitingAmountTo: this.request.awaitingAmountTo || undefined,
          overdueAmountFrom: this.request.overdueAmountFrom || undefined,
          overdueAmountTo: this.request.overdueAmountTo || undefined,
        },
        {
          responseType: 'arraybuffer',
          timeout: 30000,
        }
      )
      .then(res => {
        let filename = 'дз-отчет.xlsx';
        const headers = res.headers;
        const contentDisposition = headers['content-disposition'];

        if (contentDisposition) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(contentDisposition);

          if (matches != null && matches[1]) {
            filename = decodeURI(matches[1].replace(/['"]/g, ''));
          }
        }
        const blob = new Blob([res.data as BlobPart], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = filename.replace('задолжность', 'задолженность');
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {
        console.warn('loadXlsxReport', error);
      });
  }

  setSubjectsTotalReportsData(
    items: { code?: string; title?: string }[],
    reportList: Record<string, StatPaymentsReport>,
    mode: ReceivableRoleEmployee
  ): void {
    this.subjectsTotalReportsDataByCode = {};
    items?.forEach(item => {
      this.subjectsTotalReportsDataByCode[item.code] = {
        title: item.title,
        accountsReceivable: reportList[item.code].dueAmount,
        overdueReceivables: reportList[item.code].overdueAmount,
      };
    });
    this.subjectsTotalReportsDataModeCode = this.selectedCrumbs[mode]?.code;
    this.isTotalReceivableDataLoading = false;
  }

  setTotalReceivableData(report: StatPaymentsReport): void {
    this.accountsReceivable = report?.dueAmount ?? null;
    this.overdueReceivables = report?.overdueAmount ?? null;

    runInAction(() => (this.totalReceivableData = report ?? null));
  }
  setResultReceivableEmployees(data: AxiosResponse<StatPaymentsReceivableReportResponse>, tab: ReceivableTabNames): void {
    if (this.receivablePage === 1) {
      this.customersByCode = {};
      this.contractsByCode = {};
      this.creditStatesByCodes = {};
      this.notDistributedStatesByCodes = {};
    }
    if (data.data.customers?.length) {
      data.data.customers.forEach(customer => {
        this.customersByCode[customer.code] = {
          customer: { ...customer },
          awaitingAmount: 0, // ДЗ
          overdueAmount: 0, // ПДЗ
          notDistributed: 0,
          totalLoanAmount: 0,
          customerDaysLeft: { from: 0, to: 0 },
          customerDaysOverdue: { from: 0, to: 0 },
          plansByContractCode: {},
          customerPlannedDate: '',
          otherPlans: {
            plans: [],
            awaitingAmount: 0,
            overdueAmount: 0,
            daysOverduePeriod: new Set(),
          },
        };
      });
    }
    if (data.data?.contracts?.length) {
      data.data?.contracts?.forEach(item => {
        this.contractsByCode[item.code] = item;
      });
    }

    if (data.data?.creditStates?.length) {
      data.data?.creditStates?.forEach(state => {
        this.creditStatesByCodes[state.branchOfficeCode + state.customerCode] = state;
      });
    }

    if (data.data?.notDistributed?.length) {
      data.data?.notDistributed?.forEach(state => {
        this.notDistributedStatesByCodes[state.branchOfficeCode + state.customerCode] = state;
      });
    }

    if (data.data.plans?.length) {
      data.data.plans?.forEach(plan => {
        // если в плане есть customerCode и такой customer пришел
        if (plan?.customerCode && this.customersByCode[plan?.customerCode]) {
          // если в плане есть код договора
          if (plan?.contractCode) {
            // договор уже добавлен
            if (this.customersByCode[plan?.customerCode]?.plansByContractCode[plan?.contractCode]) {
              // в плане есть код счета
              if (plan?.billMdmCode) {
                // счет уже добавлен
                if (this.customersByCode[plan?.customerCode]?.plansByContractCode[plan?.contractCode].byBillCode[plan?.billMdmCode]) {
                  this.customersByCode[plan?.customerCode]?.plansByContractCode[plan?.contractCode].byBillCode[
                    plan?.billMdmCode
                  ].shipments.push(plan);
                } else {
                  // счет еще не добавлен
                  this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].byBillCode[plan?.billMdmCode] = {
                    shipments: [],
                    awaitingAmount: 0,
                    overdueAmount: 0,
                    billDaysLeft: { from: 0, to: 0 },
                    billDaysOverdue: { from: 0, to: 0 },
                    billPlannedDate: '',
                  };
                  this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].byBillCode[plan?.billMdmCode].shipments =
                    [plan];
                }
                this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].byBillCode[
                  plan?.billMdmCode
                ].awaitingAmount += plan?.dueAmount || 0;
                this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].byBillCode[
                  plan?.billMdmCode
                ].overdueAmount += plan?.overdueAmount || 0;
                this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].byBillCode[
                  plan?.billMdmCode
                ].billDaysLeft = mapReceivableFromTo(
                  this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].byBillCode[plan?.billMdmCode]
                    .billDaysLeft,
                  plan.daysLeft || 0
                );
                this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].byBillCode[
                  plan?.billMdmCode
                ].billDaysOverdue = mapReceivableFromTo(
                  this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].byBillCode[plan?.billMdmCode]
                    .billDaysOverdue,
                  plan.daysOverdue || 0
                );
                this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].byBillCode[
                  plan?.billMdmCode
                ].billPlannedDate = mapReceivablePlannedDate(
                  this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].byBillCode[plan?.billMdmCode]
                    .billPlannedDate,
                  plan.plannedDate
                );
              } else {
                // в плане нет кода счета
                this.customersByCode[plan?.customerCode]?.plansByContractCode[plan?.contractCode].shipmentCodeOnly.push(plan);
              }
              // считаем сумму ДЗ и ПДЗ по договору
              this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].awaitingAmount += plan?.dueAmount || 0;
              this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].overdueAmount += plan?.overdueAmount || 0;
              this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].contractLoanAmount += plan.loanAmount || 0;
              this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].maxInterestRatePct =
                plan.maxInterestRatePct || 0;
              this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].contractDaysLeft = mapReceivableFromTo(
                this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].contractDaysLeft,
                plan.daysLeft || 0
              );
              this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].contractPlannedDate =
                mapReceivablePlannedDate(
                  this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].contractPlannedDate,
                  plan.plannedDate
                );
            } else {
              // договор еще не добавлен
              this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode] = {
                byBillCode: {},
                shipmentCodeOnly: [],
                awaitingAmount: 0, // ДЗ
                overdueAmount: 0, // ПДЗ
                daysOverduePeriod: new Set(),
                contractLoanAmount: 0,
                maxInterestRatePct: 0,
                contractDaysLeft: { from: 0, to: 0 },
                contractPlannedDate: '',
              };
              // в плане есть код счета
              if (plan?.billMdmCode) {
                // счет уже создан
                if (this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].byBillCode[plan?.billMdmCode]) {
                  this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].byBillCode[
                    plan?.billMdmCode
                  ].shipments.push(plan);
                } else {
                  this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].byBillCode[plan?.billMdmCode] = {
                    shipments: [],
                    awaitingAmount: 0,
                    overdueAmount: 0,
                    billDaysLeft: { from: 0, to: 0 },
                    billDaysOverdue: { from: 0, to: 0 },
                    billPlannedDate: '',
                  };
                  this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].byBillCode[plan?.billMdmCode].shipments =
                    [plan];
                }
                this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].byBillCode[
                  plan?.billMdmCode
                ].awaitingAmount += plan?.dueAmount || 0;
                this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].byBillCode[
                  plan?.billMdmCode
                ].overdueAmount += plan?.overdueAmount || 0;
                this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].byBillCode[
                  plan?.billMdmCode
                ].billDaysLeft = mapReceivableFromTo(
                  this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].byBillCode[plan?.billMdmCode]
                    .billDaysLeft,
                  plan.daysLeft || 0
                );
                this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].byBillCode[
                  plan?.billMdmCode
                ].billDaysOverdue = mapReceivableFromTo(
                  this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].byBillCode[plan?.billMdmCode]
                    .billDaysOverdue,
                  plan.daysOverdue || 0
                );
                this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].byBillCode[
                  plan?.billMdmCode
                ].billPlannedDate = mapReceivablePlannedDate(
                  this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].byBillCode[plan?.billMdmCode]
                    .billPlannedDate,
                  plan.plannedDate
                );
              } else {
                this.customersByCode[plan?.customerCode]?.plansByContractCode[plan?.contractCode].shipmentCodeOnly.push(plan);
              }
              // считаем сумму ДЗ и ПДЗ по договору
              this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].awaitingAmount += plan?.dueAmount || 0;
              this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].overdueAmount += plan?.overdueAmount || 0;
              this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].contractLoanAmount += plan.loanAmount || 0;
              this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].maxInterestRatePct =
                plan.maxInterestRatePct || 0;
              this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].contractDaysLeft = mapReceivableFromTo(
                this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].contractDaysLeft,
                plan.daysLeft || 0
              );
              this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].contractPlannedDate =
                mapReceivablePlannedDate(
                  this.customersByCode[plan?.customerCode].plansByContractCode[plan?.contractCode].contractPlannedDate,
                  plan.plannedDate
                );
            }
          } else {
            // в плане нет кода договора
            this.customersByCode[plan?.customerCode].otherPlans.awaitingAmount += plan?.dueAmount || 0;
            this.customersByCode[plan?.customerCode].otherPlans.overdueAmount += plan?.overdueAmount || 0;
            this.customersByCode[plan?.customerCode].otherPlans.plans.push(plan);
          }
          // находим все значения просрочки
          if (plan?.contractCode) {
            plan.daysOverdue &&
              this.customersByCode[plan?.customerCode]?.plansByContractCode[plan?.contractCode].daysOverduePeriod.add(plan.daysOverdue);
          } else {
            plan.daysOverdue && this.customersByCode[plan?.customerCode].otherPlans.daysOverduePeriod.add(plan.daysOverdue);
          }
          this.customersByCode[plan?.customerCode].customerDaysLeft = mapReceivableFromTo(
            this.customersByCode[plan?.customerCode].customerDaysLeft,
            plan?.daysLeft || 0
          );
          this.customersByCode[plan?.customerCode].customerDaysOverdue = mapReceivableFromTo(
            this.customersByCode[plan?.customerCode].customerDaysOverdue,
            plan?.daysOverdue || 0
          );
          this.customersByCode[plan?.customerCode].customerPlannedDate = mapReceivablePlannedDate(
            this.customersByCode[plan?.customerCode].customerPlannedDate,
            plan?.plannedDate
          );
        }
        // прибавляем ПДЗ, ДЗ, Стоимость (13,87%/год)
        this.customersByCode[plan?.customerCode].awaitingAmount += plan?.dueAmount || 0;
        this.customersByCode[plan?.customerCode].overdueAmount += plan?.overdueAmount || 0;
        this.customersByCode[plan?.customerCode].totalLoanAmount += plan.loanAmount || 0;
      });
    }
    this.canLoadMore = (data.data.customers?.length || 0) >= this.countPerPage;

    runInAction(() => {
      this.isLoading = false;
      this.isLoadingMore = false;
      this.isLoadedTabs[tab] = true;
    });
  }
  setRequestFromObj(req: AllowedReceivableParams): void {
    set(this.request, req);
  }
  actualizeRouter(request: AllowedReceivableParams): void {
    if (!this.routerControlEnabled) {
      return;
    }
    const req = toJS(request);
    this.routerStore.replace(
      {
        pathname: this.routerStore.router.pathname,
        query: { ...req },
      },
      undefined,
      { shallow: true }
    );
  }
  setMode(mode: ReceivableRoleEmployee): boolean {
    if (mode === this.mode) {
      return false;
    }
    const roleIndex = ReceivableRoleEmployeeTypes.indexOf(this.role);
    const modeParamIndex = ReceivableRoleEmployeeTypes.indexOf(this.request.mode);
    if (roleIndex > modeParamIndex && this.request.mode) {
      console.warn('Нельзя установить mode выше роли. Значение mode установится по умолчанию');
      this.snackbarStore.showInfo('Вам недоступен просмотр этого уровня. Значения установятся по умолчанию');
      this.mode = this.role;
      return false;
    }
    this.setSelectedTab(this.selectedTab);

    this.mode = mode;
    Object.keys(this.isLoadedTabs).forEach(tab => {
      this.isLoadedTabs[tab] = false;
    });
    return true;
  }
  /**
   * Смена таба
   */
  setSelectedTab(value: ReceivableTabNames): void {
    this.selectedTab = value;
    this.setRequestFromObj(observable({ tab: value }));
    this.actualizeRouter(this.request);
  }

  /**
   *
   * Справочники для отображения страницы статистики
   * @param object объект { роль: код } для подгрузки данных по определенному коду следующие, по иерархии, данные.
   * (Загрузить по коду отдела всех менеджеров)
   * @param init флаг отвечающий за первую прогрузку
   */
  async loadStatReferences(
    {
      employee: employeeCode,
      department: departmentCode,
      office: branchOfficeCode,
      division: divisionCode,
    }: {
      employee?: Code;
      department?: Code;
      office?: Code;
      division?: Code;
    },
    init = false
  ): Promise<void> {
    runInAction(() => {
      this.isLoading = true;
    });
    const res = await this.apiStore
      .apiStatApi()
      .statMarginalityReferences(employeeCode, departmentCode, branchOfficeCode, divisionCode, this.request.dateFrom || undefined);
    this.setResultMarginalityReferences(res, init);
  }

  setResultMarginalityReferences({ data }: AxiosResponse<StatReferencesResponse>, init = false): void {
    this.setCrumbsValues(data);
    if (init) {
      const roleIndex = ReceivableRoleEmployeeTypes.indexOf(this.role);
      const currentMode = this.mode; //* текущий мод из урла. Если нет то тут будет мод по роли и дальше все пойдет по маслу.
      const modeParamIndex = ReceivableRoleEmployeeTypes.indexOf(currentMode);
      if (roleIndex <= modeParamIndex) {
        // * подгружаем данные в крошки, которые идут ДО крошки по моду (включительно)
        const crumbsBeforeRole = ReceivableRoleEmployeeTypes.slice(0, modeParamIndex + 1).reverse();

        crumbsBeforeRole.forEach((crumbs, index) => {
          if (this.crumbsValues[crumbs].length > 0) {
            if (crumbs === this.mode) {
              //* ищем, для переданной крошки из урла, значение
              const valueByUrl = this.crumbsValues[crumbs].find(crumb => crumb.code === this.request.selectedCrumbCode);
              if (valueByUrl) {
                this.selectedCrumbs[crumbs] = valueByUrl;
              } else {
                //* может быть такое что не нашлось значение и просто берем по дефолту
                this.selectedCrumbs[crumbs] = this.crumbsValues[crumbs][0];
              }
            } /* else if (crumbs == this.role) {
              //* если в урле ничего не передается берем по роли
              this.selectedCrumbs[crumbs] = this.crumbsValues[crumbs][0];
            } */ else {
              //* собираем крошку НЕ переданной из урла и формируем ее на основе Переданной крошки, и остальные так же
              const selectedCrumbsBefore = this.selectedCrumbs[crumbsBeforeRole[index - 1]];
              const selectedCrumbsBeforeCode = selectedCrumbsBefore?.[getPropertyByEntity[crumbs]];
              //* бывает так когда нет у крошки ключа по которой можно ее найти (company не имеет связки с division)
              if (selectedCrumbsBeforeCode) {
                this.selectedCrumbs[crumbs] = this.crumbsValues[crumbs].find(crumb => crumb.code === selectedCrumbsBeforeCode);
              } else {
                this.selectedCrumbs[crumbs] = this.crumbsValues[crumbs][0];
              }
            }
          } else {
            console.warn('crumbsValues with "crumbs" is not exist');
          }
        });
      } else {
        this.snackbarStore.showInfo('Вам недоступен просмотр этого уровня.');
        console.warn('Вам недоступен просмотр этого уровня');
      }

      this.enabledCrumbs = ReceivableRoleEmployeeTypes.slice(roleIndex, modeParamIndex + 1);
      const nextMode = ReceivableRoleEmployeeTypes[modeParamIndex + 1];
      if (nextMode && this.crumbsValues[nextMode].length >= 1) {
        if (this.crumbsValues[nextMode].length === 1) {
          this.selectedCrumbs[nextMode] = this.crumbsValues[nextMode][0];
        }
        this.enabledCrumbs.push(nextMode);
      }
    }
  }
  setCrumbsValues(data: StatReferencesResponse): void {
    this.crumbsValues[RECEIVABLE_ROLE_EMPLOYEE_CODES.company] = data.headCompanies || [];
    this.crumbsValues[RECEIVABLE_ROLE_EMPLOYEE_CODES.division] = data.divisions || [];
    this.crumbsValues[RECEIVABLE_ROLE_EMPLOYEE_CODES.office] = [...(data.offices || [])].map(i => ({ ...i, title: i.name }));
    this.crumbsValues[RECEIVABLE_ROLE_EMPLOYEE_CODES.department] = data.departments || [];
    this.crumbsValues[RECEIVABLE_ROLE_EMPLOYEE_CODES.employee] = [...(data.employees || [])].map(employee => ({
      code: employee.code,
      title: `${employee.surname || ''} ${employee.name || ''} ${employee.patronymic || ''}`,
      departmentCode: employee.departmentCode,
    }));
  }

  /**
   * Выбираем значение из списка крошки.
   */
  async setSelectedCrumbAndSetMode(crumbName: ReceivableRoleEmployee, code: Code): Promise<void> {
    const prevMode = this.mode;
    const prevSelectedCrumbsCode = this.selectedCrumbs[crumbName]?.code;
    const selectedCrumb = this.crumbsValues[crumbName].find(crumb => crumb.code === code);

    if (!this.setMode(crumbName) && prevSelectedCrumbsCode === selectedCrumb.code) {
      return;
    }
    this.setRequestFromObj(observable({ mode: crumbName, selectedCrumbCode: selectedCrumb.code }));
    this.actualizeRouter(this.request);
    Object.keys(this.isLoadedTabs).forEach(tab => {
      this.isLoadedTabs[tab] = false;
    });
    // * если роль последняя то обновляем References(т.к. следующей крошки после этой роли нет, значит можно не обновлять данные)
    if (crumbName !== ReceivableRoleEmployeeTypes[ReceivableRoleEmployeeTypes.length - 1]) {
      await this.loadStatReferences({ [crumbName]: selectedCrumb.code });
    }
    this.unSelectCrumbs(prevMode, crumbName);
    runInAction(() => {
      this.allowTabs = allowTabsByRole[crumbName];
      this.selectedCrumbs[crumbName] = selectedCrumb;
    });

    this.loadStatByTab(RECEIVABLE_TAB_NAME_CODES.clients);
    if (RECEIVABLE_TAB_NAME_CODES.clients !== this.selectedTab) {
      this.loadStatByTab(this.selectedTab);
    }
  }

  /**
   * Клик по крошке без меню
   */
  async setModeAndUpdateCrumbs(crumbName: ReceivableRoleEmployee): Promise<void> {
    const prevMode = this.mode;
    const selectedCrumb = this.selectedCrumbs[crumbName];
    if (!this.setMode(crumbName)) {
      return;
    }
    if (crumbName !== ReceivableRoleEmployeeTypes[ReceivableRoleEmployeeTypes.length - 1] && crumbName !== this.role) {
      await this.loadStatReferences({ [crumbName]: selectedCrumb.code });
    }
    this.unSelectCrumbs(prevMode, crumbName);
    runInAction(() => {
      this.allowTabs = allowTabsByRole[crumbName];
    });
    this.setRequestFromObj(observable({ mode: crumbName, selectedCrumbCode: selectedCrumb.code }));
    this.actualizeRouter(this.request);
    this.loadStatByTab(RECEIVABLE_TAB_NAME_CODES.clients);
  }
  unSelectCrumbs(prevMode: ReceivableRoleEmployee, newMode: ReceivableRoleEmployee): void {
    const rolePrevIndex = ReceivableRoleEmployeeTypes.indexOf(prevMode);
    const roleIndex = ReceivableRoleEmployeeTypes.indexOf(newMode);

    if (roleIndex < rolePrevIndex) {
      const unSelectedCrumb = ReceivableRoleEmployeeTypes.slice(roleIndex + 1, ReceivableRoleEmployeeTypes.length);
      unSelectedCrumb.forEach(crumb => {
        this.selectedCrumbs[crumb] = undefined;
      });
      this.enabledCrumbs = this.enabledCrumbs.filter(crumb => !unSelectedCrumb.includes(crumb));
    }
    for (let nextRoleIndex = roleIndex + 1; nextRoleIndex < ReceivableRoleEmployeeTypes.length; nextRoleIndex++) {
      const nextRole = ReceivableRoleEmployeeTypes[roleIndex + 1];
      if (nextRole && this.crumbsValues[nextRole].length >= 1) {
        if (this.crumbsValues[nextRole].length === 1) {
          // Выбираем в крошке единственный вариант
          this.selectedCrumbs[nextRole] = this.crumbsValues[nextRole][0];
        } else if (this.selectedCrumbs[nextRole]) {
          // Сбрасываем выбранный вариант, если его нет в доступном списке
          this.selectedCrumbs[nextRole] = this.crumbsValues[nextRole].find(crumb => crumb === this.selectedCrumbs[nextRole]);
        }
        this.enabledCrumbs.push(nextRole);
      } else {
        this.enabledCrumbs = this.enabledCrumbs.filter(crumb => crumb !== nextRole);
      }
    }
  }
  async loadStatByTab(tab: ReceivableTabNames): Promise<void> {
    const role = this.mode;
    this.receivablePage = 1;
    this.customersByCode = {};
    if (this.isLoadedTabs[tab]) {
      return;
    }
    switch (tab) {
      case RECEIVABLE_TAB_NAME_CODES.clients:
        await this.loadReceivableStat(role);
        break;
      default:
        console.warn('tab received does not exist');
        break;
    }
  }
}
